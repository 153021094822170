import React from "react";
import DoorBackOutlinedIcon from "@mui/icons-material/DoorBackOutlined";

const PrivateRoom = () => {
  return (
    <DoorBackOutlinedIcon
      sx={{
        fontSize: "18px",
        // color: "white",
        // backgroundColor: "#82ca9d",
        borderRadius: "50%",
        verticalAlign: "middle",
      }}
    />
  );
};

export default PrivateRoom;
