import {
  RECEIVE_FOR_SALE_LISTING,
  UPDATE_LISTING_PRICE_TO_EVALUATE,
  UPDATE_DOWN_PAYMENT,
  UPDATE_DOWN_PAYMENT_PERCENTAGE,
  UPDATE_INTEREST_RATE,
  UPDATE_PROPERTY_TAX_RATE,
} from "../actions/airbnbListingsActions";

const forSaleListingReducer = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_FOR_SALE_LISTING:
      return {
        listingId: action.id,
        latitude: action.latitude,
        longitude: action.longitude,
        photoURL: action.photo_url,
        beds: action.beds,
        baths: action.baths,
        addressStreet: action.address_street,
        addressCity: action.address_city,
        addressState: action.address_state,
        addressZipCode: action.address_zip_code,
        livingArea: action.living_area,
        lotSize: action.lot_size,
        listingPrice: action.listing_price,
        estimatePrice: action.estimate_price,
        estimateRent: action.estimate_rent,
        // variables to evaluate ROI
        listingPriceToEvaluate: action.listing_price,
        downPaymentPercentage: 20.0,
        downPayment: action.listing_price * 0.2,
        interestRate: 3.125,
        propertyTaxRate: 1.1,
      };

    case UPDATE_LISTING_PRICE_TO_EVALUATE:
      return {
        ...state,
        listingPriceToEvaluate: action.listingPriceToEvaluate,
        downPayment: Math.trunc(
          (action.listingPriceToEvaluate * state.downPaymentPercentage) / 100
        ),
      };

    case UPDATE_DOWN_PAYMENT:
      return {
        ...state,
        downPayment: action.downPayment,
        downPaymentPercentage: parseFloat(
          ((action.downPayment / state.listingPriceToEvaluate) * 100).toFixed(1)
        ),
      };

    case UPDATE_DOWN_PAYMENT_PERCENTAGE:
      return {
        ...state,
        downPaymentPercentage: action.downPaymentPercentage,
        downPayment: Math.trunc(
          (state.listingPriceToEvaluate * action.downPaymentPercentage) / 100
        ),
      };

    case UPDATE_INTEREST_RATE:
      return {
        ...state,
        interestRate: action.interestRate,
      };

    case UPDATE_PROPERTY_TAX_RATE:
      return {
        ...state,
        propertyTaxRate: action.propertyTaxRate,
      };

    default:
      return state;
  }
};

export default forSaleListingReducer;
