import angie from "../assets/avatar/angie.avif";
import ben from "../assets/avatar/ben.avif";
import claire from "../assets/avatar/claire.avif";
import daniel from "../assets/avatar/daniel.avif";
import gloria from "../assets/avatar/gloria.avif";
import jason from "../assets/avatar/jason.avif";
import lester from "../assets/avatar/lester.avif";
import lori from "../assets/avatar/lori.avif";
import maggie from "../assets/avatar/maggie.avif";
import marcus from "../assets/avatar/marcus.avif";
import michael from "../assets/avatar/michael.avif";
import ning from "../assets/avatar/ning.avif";
import seiko from "../assets/avatar/seiko.avif";
import shane from "../assets/avatar/shane.avif";
import victoria from "../assets/avatar/victoria.avif";
import zane from "../assets/avatar/zane.avif";

// fake a year worth of ADR data
export const fakeADR = () => {
  const low = 50;
  const high = 250;
  const arr = [];
  let i = 0;
  for (i = 0; i < 12; i++) {
    arr.push(Math.floor(low + Math.random() * (high - low)));
  }

  return arr;
};

// fake a year worth of occupancy rate
export const fakeOccupancy = () => {
  const low = 0.15;
  const high = 0.95;
  const arr = [];
  let i = 0;
  for (i = 0; i < 12; i++) {
    arr.push(low + Math.random() * (high - low));
  }

  return arr;
};

// fake a year worth of revenue
export const fakeRevenue = () => {
  const low = 800;
  const high = 5000;
  const arr = [];
  let i = 0;
  for (i = 0; i < 12; i++) {
    arr.push(Math.floor(low + Math.random() * (high - low)));
  }

  return arr;
};

export const indexToMonth = (idx) => {
  const mapping = {
    0: "Jan",
    1: "Feb",
    2: "Mar",
    3: "Apr",
    4: "May",
    5: "Jun",
    6: "Jul",
    7: "Aug",
    8: "Sep",
    9: "Oct",
    10: "Nov",
    11: "Dec",
  };

  return mapping[idx];
};

export const fakeReviewScores = () => {
  const keys = [
    "Cleanliness",
    "Accuracy",
    "Location",
    "Communication",
    "Check-in",
    "Value",
  ];

  const low = 4.5;
  const high = 5.0;

  const scores = [];
  let i = 0;
  for (i = 0; i < 6; i++) {
    const score = low + Math.random() * (high - low);
    scores.push(score.toFixed(2));
  }

  return scores.reduce((obj, value, idx) => {
    return { ...obj, [keys[idx]]: value };
  }, {});
};

export const fakeRoomTypes = () => {
  return [
    {
      roomType: "Entire Place",
      count: 1145,
    },
    {
      roomType: "Private Room",
      count: 355,
    },
    {
      roomType: "Shared Room",
      count: 120,
    },
  ];
};

export const fakeAvailableNights = () => {
  return [
    {
      days: "less than 30 days",
      count: 123,
    },
    {
      days: "30 to 90 days",
      count: 311,
    },
    {
      days: "91 to 180 days",
      count: 455,
    },
    {
      days: "181 to 270 days",
      count: 228,
    },
    {
      days: "more than 270 days",
      count: 87,
    },
  ];
};

export const fakeBookedNights = () => {
  return [
    {
      days: "less than 30 days",
      count: 65,
    },
    {
      days: "30 to 90 days",
      count: 159,
    },
    {
      days: "91 to 180 days",
      count: 200,
    },
    {
      days: "181 to 270 days",
      count: 105,
    },
    {
      days: "more than 270 days",
      count: 53,
    },
  ];
};

export const fakeOccupancyBreakdown = () => {
  const arr = [];
  let i = 0;
  for (i = 0; i < 12; i++) {
    arr.push({
      month: indexToMonth(i),
      "25 percentile": (0.1 + Math.random() * (0.25 - 0.1)).toFixed(2),
      "50 percentile": (0.35 + Math.random() * (0.5 - 0.35)).toFixed(2),
      "75 percentile": (0.5 + Math.random() * (0.6 - 0.5)).toFixed(2),
      "95 percentile": (0.75 + Math.random() * (0.95 - 0.75)).toFixed(2),
    });
  }

  return arr;
};

export const fakeADRBreakdown = () => {
  const arr = [];
  let i = 0;
  for (i = 0; i < 12; i++) {
    arr.push({
      month: indexToMonth(i),
      "25 percentile": 36,
      "50 percentile": 55,
      "75 percentile": 85,
      "95 percentile": 100,
    });
  }

  return arr;
};

export const fakeCancellationPolicy = () => {
  return [
    {
      name: "strict",
      count: 17,
      percent: 0.17,
    },
    {
      name: "moderate",
      count: 37,
      percent: 0.37,
    },
    {
      name: "flexible",
      count: 43,
      percent: 0.43,
    },
    {
      name: "whatever",
      count: 3,
      percent: 0.03,
    },
  ];
};

export const fakeMinNights = () => {
  return [
    {
      name: 1,
      count: 17,
      percent: 0.27,
    },
    {
      name: 2,
      count: 37,
      percent: 0.22,
    },
    {
      name: 3,
      count: 43,
      percent: 0.07,
    },
    {
      name: 4,
      count: 13,
      percent: 0.03,
    },
    {
      name: 5,
      count: 13,
      percent: 0.13,
    },
    {
      name: "6+",
      count: 13,
      percent: 0.28,
    },
  ];
};

export const fakeADRRange = () => {
  const dates = [
    {
      date: 1609459200000,
    },
    {
      date: 1612137600000,
    },
    {
      date: 1614556800000,
    },
    {
      date: 1617235200000,
    },
    {
      date: 1619827200000,
    },
    {
      date: 1622505600000,
    },
    {
      date: 1625097600000,
    },
    {
      date: 1627776000000,
    },
    {
      date: 1630454400000,
    },
    {
      date: 1633046400000,
    },
    {
      date: 1635724800000,
    },
    {
      date: 1638316800000,
    },
  ];

  return dates.map((item) => {
    return {
      ...item,
      adrPercentiles: {
        "5.0": 30,
        "25.0": 125,
        "50.0": 150,
        "75.0": 225,
        "95.0": 580,
      },
    };
  });
};

export const userCommentsData = () => {
  return [
    {
      avatar: angie,
      name: "Angie S",
      profession: "Superhost",
      comment:
        "Definitely impressed by the accuracy and depth of data I've been able to access. The dynamic pricing feature has been a game-changer for me - I've seen a significant increase in revenue since I started using it. And it's completely free!",
    },
    {
      avatar: ben,
      name: "Ben L",
      profession: "Analyst",
      comment:
        "The data is fresh, high-quality, and matches up with Airbnb's quarterly earnings reports. A big shoutout to tech support for answering my questions too!",
    },
    {
      avatar: claire,
      name: "Claire B",
      profession: "Real Estate Investor",
      comment:
        "AirROI's precise market analysis gave me the confidence to invest in a new city overseas. The insights I gained were crucial in closing the deal and expanding my business.",
    },

    {
      avatar: daniel,
      name: "Daniel S",
      profession: "Property Manager",
      comment:
        "I use AirROI to stay up-to-date on the latest trends and insights in the short-term rental market. The trending and pacing features have been incredibly valuable in helping me identify opportunities and optimize my clients' listings.",
    },
    {
      avatar: gloria,
      name: "Gloria G",
      profession: "Superhost",
      comment:
        "Wow, I was skeptical about using a free tool for my short-term rental business, but I've been pleasantly surprised. The interface is user-friendly, the data is accurate and comprehensive, and the customer support has been top-notch. I've already recommended it to all my fellow hosts!",
    },
    {
      avatar: jason,
      name: "Jason K",
      profession: "Superhost",
      comment:
        "After trying a few different analytics tools for my Airbnb business, this one is hands-down the best. The dynamic pricing feature has helped me stay competitive and increase my revenue, and the seasonality charts have given me a better understanding of my market.",
    },

    {
      avatar: lester,
      name: "Lester H",
      profession: "Channel Manager",
      comment:
        "I need to provide my clients with actionable insights and data-driven recommendations. AirROI does exactly that and even overdelivers on these requirements.",
    },
    {
      avatar: lori,
      name: "Lori S",
      profession: "Superhost",
      comment:
        "My husband and I have been using this platform for a while now, and we've seen a solid increase in my bookings and revenue. The forward-looking charts have helped me identify opportunities to optimize my listings and improve my guest experience.",
    },
    {
      avatar: maggie,
      name: "Maggie J",
      profession: "Developer",
      comment:
        "Really appreciated the early access to the API preview! It's been easy to integrate this data into my own applications, and the support team has been super helpful in getting me up and running.",
    },

    {
      avatar: marcus,
      name: "Marcus C",
      profession: "Host",
      comment:
        "I'm just starting out as a host, and I was feeling overwhelmed. But this platform has been a lifesaver!",
    },
    {
      avatar: michael,
      name: "Michael S",
      profession: "Revenue Manager",
      comment:
        "The granularity of filters is awesome! I can drill down to the exact type of properties with the exact number of bedrooms I'm interested in to optimize my listings.",
    },

    {
      avatar: ning,
      name: "Ning Z",
      profession: "Channel Manager",
      comment:
        "The dashboard and charts are intuitive, the forward-looking analytics are super useful for my pricing strategy.",
    },
    {
      avatar: seiko,
      name: "Seiko Y",
      profession: "Channel Manager",
      comment:
        "The data on this platform are more consistent and accurate compared to other options on the market. I highly recommend AirROI.",
    },

    {
      avatar: shane,
      name: "Shane G",
      profession: "Consultant",
      comment:
        "With intuitive data visualization tools and the ability to drill down into specific metrics, it feels very natural to use.",
    },
    {
      avatar: victoria,
      name: "Victoria M",
      profession: "Superhost",
      comment:
        "Thanks for showing me the ropes for the new features. The revenue and occupancy estimates you guys calculated are spot-on. Can't wait for the future releases you mentioned!",
    },
    {
      avatar: zane,
      name: "Zane O",
      profession: "Superhost",
      comment:
        "AirROI is very feature rich and accurate, I use it whenever I need to analyze the market and make data-driven decisions.",
    },
  ];
};

export const normalizeDemand = (demand) => {
  let res;

  if (demand === 0 || demand == null) {
    res = 9; // give it unknown key
  } else if (demand < 1) {
    res = 1;
  } else if (demand > 8) {
    res = 8;
  } else {
    res = Math.round(demand);
  }

  return res - 1; // converting from [1-9] to [0-8]
};
