import React from "react";
import SingleBedOutlined from "@mui/icons-material/SingleBedOutlined";

const SharedRoom = () => {
  return (
    <SingleBedOutlined
      sx={{
        fontSize: "20px",
        // color: "white",
        // backgroundColor: "#83a6ed",
        borderRadius: "50%",
        verticalAlign: "middle",
      }}
    />
  );
};

export default SharedRoom;
