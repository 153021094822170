import React from "react";
import DryCleaningOutlinedIcon from "@mui/icons-material/DryCleaningOutlined";

const Hotel = () => {
  return (
    <DryCleaningOutlinedIcon
      sx={{
        // color: "white",
        // backgroundColor: "#ffc658",
        borderRadius: "50%",
        verticalAlign: "middle",
      }}
    />
  );
};

export default Hotel;
