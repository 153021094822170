// check to refresh every 30 minutes, it's important that this variable needs to be less than REFRESH_TOKEN_LEAD_TIME, and also less than max expiry time of accessToken and idToken which is 1 day set by cognito
export const REFRESH_TOKEN_INTERVAL = 1000 * 60 * 30; // unit is millis

// refresh token if it expires in 60 minutes or less
export const REFRESH_TOKEN_LEAD_TIME = 60 * 60; // unit is seconds due to cognito api

export const POST_SIGN_UP_LOG_IN_DELAY = 250; // wait for lambda to auto-confirm user (best-effort)

export const LOG_IN_MODAL = "LOG_IN_MODAL";
export const LOG_OUT_MODAL = "LOG_OUT_MODAL";
export const SIGN_UP_MODAL = "SIGN_UP_MODAL";
export const FORGOT_PASSWORD_MODAL = "FORGOT_PASSWORD_MODAL";
export const CONFIRM_PASSWORD_MODAL = "CONFIRM_PASSWORD_MODAL";
