import Hotel from "../components/icons/Hotel";
import EntireHome from "../components/icons/EntireHome";
import SharedRoom from "../components/icons/SharedRoom";
import PrivateRoom from "../components/icons/PrivateRoom";

export const ENTIRE_HOME = "entire_home";
export const PRIVATE_ROOM = "private_room";
export const SHARED_ROOM = "shared_room";
export const HOTEL = "hotel_room";

export const ROOM_TYPES = {
  [ENTIRE_HOME]: "Entire Place",
  [PRIVATE_ROOM]: "Private Room",
  [SHARED_ROOM]: "Shared Room",
  [HOTEL]: "Hotel Room",
};

export const ROOM_ICONS = {
  [ENTIRE_HOME]: EntireHome,
  [PRIVATE_ROOM]: PrivateRoom,
  [SHARED_ROOM]: SharedRoom,
  [HOTEL]: Hotel,
};
