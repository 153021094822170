import React from "react";
import { Box } from "@mui/material";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import tos from "../../src/assets/legal/terms-of-service.md";
import { markDownStyles } from "./cssvars";
import withErrorBoundary from "./error/withErrorBoundary";

const TermsOfService = () => {
  return (
    <Box
      sx={{
        ...markDownStyles,
        backgroundColor: "#fafafa",
        fontFamily: `"Lato", "sans-serif"`,
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "888px",
          px: { xs: 2, md: 4 },
          pb: { xs: 1, md: 8 },
        }}
      >
        <ReactMarkdown remarkPlugins={[remarkGfm]}>{tos}</ReactMarkdown>
      </Box>
    </Box>
  );
};

export default withErrorBoundary(TermsOfService);
