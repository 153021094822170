// using luxon because date-fns only supports 1.x binding with date-io
import { startOfWeek } from "date-fns";
import { DateTime } from "luxon";
import { indexToMonth } from "./dataUtil";

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const MONTHS_ABBREVIATIONS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const DOW_ABBREVIATIONS = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

export const timeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const minDate = DateTime.now().minus({ years: 5 }).startOf("month");
export const maxDate = DateTime.now().minus({ years: 1 }).startOf("month");

export const defaultFromDate = DateTime.now()
  .minus({ years: 3 })
  .plus({ months: 1 })
  .startOf("month");
export const defaultToDate = DateTime.now()
  .minus({ month: 1 })
  .startOf("month");

export const leapYear = (year) => {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

export const daysBetween = (date1, date2) => {
  return Math.abs(date1.getTime() - date2.getTime()) / (1000 * 3600 * 24);
};

/**
 * Creates a JavaScript Date object in the local time zone from a UTC timestamp in milliseconds,
 * keeping the same year, month, day, hour, minute, and second values.
 * Essentially, this changes the time zone to the browser's local time zone without adjusting the time values.
 * This is useful for preventing off-by-one errors in chart X-axis date labels when working with dates.
 *
 * @param {number} utcMillis - The UTC timestamp in milliseconds.
 * @returns {Date} A JavaScript Date object in the local time zone with unadjusted time values.
 */
export const toLocalDateWithUtcTimeValues = (utcMillis) => {
  return DateTime.fromMillis(utcMillis, { zone: "utc" })
    .setZone(undefined, { keepLocalTime: true })
    .toJSDate();
};

/**
 * Converts a UTC timestamp in milliseconds to a formatted date string in the browser's local time zone,
 * adjusting the time values to reflect the local time.
 *
 * @param {number} utcMillis - The UTC timestamp in milliseconds.
 * @returns {string} A formatted date string in 'yyyy-MM-dd HH:mm:ss' format in the local time zone.
 */
export const convertUtcMillisToLocalTime = (utcMillis) => {
  return DateTime.fromMillis(utcMillis, { zone: "utc" })
    .setZone("local")
    .toFormat("yyyy-MM-dd HH:mm:ss");
};

export const getLocalTimeZoneAbbreviation = () => {
  const options = {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  const dateFormat = new Intl.DateTimeFormat("en-US", {
    ...options,
    timeZoneName: "short",
  });

  const parts = dateFormat.formatToParts(new Date());
  const timeZonePart = parts.find((part) => part.type === "timeZoneName");

  return timeZonePart ? timeZonePart.value : "";
};

// converting js datetime
export const toUTCKeepLocalTime = (datetime) => {
  return datetime.setZone("UTC", { keepLocalTime: true });
};

// converting luxon datetime
export const toUTCMillis = (luxonDateTime) => {
  return DateTime.fromObject(
    {
      day: luxonDateTime.day,
      month: luxonDateTime.month,
      year: luxonDateTime.year,
    },
    { zone: "utc" }
  ).toMillis();
};

// month is 0 indexed and day is 1 indexed,  day of the week is also 0 indexed, 0 -6 represents Sunday to Saturday
export const monthParams = (year, month) => {
  const date = new Date();
  date.setFullYear(year);
  date.setMonth(month);
  date.setDate(1);

  const startDayOfWeek = date.getDay();

  const numOfDays = new Date(year, month + 1, 0).getDate();

  return {
    startDayOfWeek,
    numOfDays,
  };
};

// calc height, width, and 2-d array of grid
export const calendarBigGridParams = (startDayOfWeek, numOfDays) => {
  const startGap = startDayOfWeek;
  let totalCells = startGap + numOfDays;

  const width = 7;
  const height = Math.ceil(totalCells / width);
  totalCells += totalCells % width;

  const cells = [];

  for (let i = 0; i < totalCells; i++) {
    if (i < startGap || i - startGap >= numOfDays) {
      cells.push({ day: null, r: Math.trunc(i / width), c: i % width });
    } else {
      cells.push({
        day: i - startGap + 1,
        r: Math.trunc(i / width),
        c: i % width,
      });
    }
  }

  return {
    height,
    width,
    cells,
  };
};

export const calendarSmallGridParams = (days) => {
  const height = 7; // name is height because we lay out days of the week veritically, different from big calendar layout
  const numDays = days.length;
  const startDate = DateTime.fromMillis(days[0].date, {
    zone: "utc",
  }).toJSDate();
  const endDate = DateTime.fromMillis(days[numDays - 1].date, {
    zone: "utc",
  }).toJSDate();

  const startDayOfWeek = startDate.getDay();
  const startGap = startDayOfWeek + 1;
  const endGap = height - 1 - endDate.getDay();
  const totalCells = startGap + numDays + endGap;

  const cells = [];
  const headers = []; // payload is month and column number (0-indexed)

  for (let i = 0; i < totalCells; i++) {
    let day;
    if (i >= startGap && i < totalCells - endGap) {
      day = days[i - startGap];
      const date = DateTime.fromMillis(day.date, { zone: "utc" }).toJSDate();

      if (date.getDate() === 1) {
        headers.push({
          month: MONTHS_ABBREVIATIONS[date.getMonth()],
          c: Math.trunc(i / height),
        });
      }
    } else {
      day = null;
    }

    cells.push({
      day,
      r: i % height,
      c: Math.trunc(i / height),
    });
  }

  return {
    startDate,
    endDate,
    startDayOfWeek,
    totalDays: numDays,
    headers,
    cells,
  };
};

const forward12MonthsStart = DateTime.now().startOf("month");
const forward12MonthsEnd = DateTime.now().plus({ months: 11 }).endOf("month"); // slice 1 year worth of data
const backward12MonthsEnd = forward12MonthsStart.minus({ days: 1 });
const backward12MonthsStart = forward12MonthsStart
  .minus({ months: 12 })
  .startOf("month");

export const [
  forward12MonthsStartMillis,
  forward12MonthsEndMillis,
  backward12MonthsStartMillis,
  backward12MonthsEndMillis,
] = [
  toUTCMillis(forward12MonthsStart),
  toUTCMillis(forward12MonthsEnd),
  toUTCMillis(backward12MonthsStart),
  toUTCMillis(backward12MonthsEnd),
];

// luxon date
export const getMonthYear = (date) => {
  const { month, year } = date;

  return [indexToMonth(month - 1), year % 100];
};

export const getMonthYearLabel = (epoch) => {
  const date = new Date(epoch);
  const month = indexToMonth(date.getUTCMonth());
  const year = date.getUTCFullYear() % 100;
  return `${month} '${year}`;
};

export const epochToMonthLabel = (epoch) => {
  const date = new Date(epoch);
  return date.toLocaleString("en-US", { month: "short", timeZone: "UTC" });
};

export const fullYearTo2DigitsYear = (dateStr) => {
  const date = new Date(dateStr);
  return date
    .toLocaleDateString("en-US", {
      year: "2-digit",
      month: "short",
      day: "numeric",
    })
    .replace(",", ", ‎ "); // <200e> forces a white-space, because regular whitespaces gets ignored by recharts somehow maybe due to svg
};
