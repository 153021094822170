import { API_HOST } from "../constants/bootstrap";
import { setToast } from "./toastActions";

export const submitForm = (formType, jsonPayload) => {
  return async (dispatch) => {
    const baseUrl = `${API_HOST}/forms/submit_form`;

    let fetchRequestInfo = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        type: formType,
        data: jsonPayload,
      }),
    };

    try {
      const response = await fetch(baseUrl, fetchRequestInfo);
      if (response.ok) {
        const result = await response.json();
        dispatch(
          setToast({
            level: "success",
            message: "Submitted successfully.",
          })
        );
      } else {
        dispatch(
          setToast({
            level: "error",
            message: "Error ocurred during submitting. Please try again.",
          })
        );
      }
    } catch (error) {
      dispatch(
        setToast({
          level: "error",
          message: "Error ocurred during submitting. Please try again.",
        })
      );
    }
  };
};
