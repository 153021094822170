import React from "react";
import ErrorBoundaryRender from "./ErrorBoundaryRender";
import * as Sentry from "@sentry/react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // log errors to sentry
    // note: without this production mode would not send errors to Sentry, in development mode it does not this line because React intentinally makes error trigger twice which makes the second time uncaught and bubble up and gets seen by Sentry thus sending it automatically
    Sentry.captureException(error, { extra: errorInfo });
  }

  render() {
    if (this.state.hasError) {
      return <ErrorBoundaryRender error={this.state.error} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
