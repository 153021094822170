import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import LoadingIndicator from "./LoadingIndicator";
import AppHeader from "./AppHeader";
import Toast from "./Toast";
import { GoogleAnalyticsPageTracker } from "./GoogleAnalyticsPageTracker";
import withErrorBoundary from "./error/withErrorBoundary";
import {
  MAP,
  ACCOUNTS,
  CHARTS,
  DYNAMIC_PRICE,
  INVENTORY,
  LISTINGS,
  OCCUPANCY,
  RATES,
  REVENUE,
  SEASONALITY,
  TREND,
} from "../constants/pages";
import ContactUs from "./ContactUs";
import FAQ from "./FAQ";
import AboutUs from "./AboutUs";
import PrivacyAndCookiePolicy from "./PrivacyAndCookiePolicy";
import TermsOfService from "./TermsOfService";

// Lazy load components
const Home = lazy(() => import("./Home"));
const Atlas = lazy(() => import("./Atlas"));
const BlogIndex = lazy(() => import("./blog/BlogIndex"));
const BlogPost = lazy(() => import("./blog/BlogPost"));
const DynamicPrice = lazy(() => import("./DynamicPrice"));
const ChannelAccounts = lazy(() => import("./dynamic-pricing/ChannelAccounts"));
const AirbnbMap = lazy(() => import("./AirbnbMap"));
const InventoryCharts = lazy(() => import("./charts/InventoryCharts"));
const RevenueCharts = lazy(() => import("./charts/RevenueCharts"));
const OccupancyCharts = lazy(() => import("./charts/OccupancyCharts"));
const RatesCharts = lazy(() => import("./charts/RatesCharts"));
const SeasonalityCharts = lazy(() => import("./charts/SeasonalityCharts"));
const TrendCharts = lazy(() => import("./charts/TrendCharts"));
const PricingDashboard = lazy(() =>
  import("./dynamic-pricing/PricingDashboard")
);
const ListingPricing = lazy(() => import("./dynamic-pricing/ListingPricing"));

const RoutedApp = () => {
  const atlasReady = useSelector((state) => state.airbnbListings.ready);
  const dynamicPricingReady = useSelector(
    (state) => state.dynamicPricing.dashboard.ready
  );

  return (
    <Router>
      <GoogleAnalyticsPageTracker>
        <Toast />
        <AppHeader />

        <Suspense fallback={<LoadingIndicator ml={3} />}>
          <Routes>
            <Route path="/atlas" element={<Atlas />}>
              <Route
                path={MAP}
                element={
                  atlasReady ? <AirbnbMap /> : <LoadingIndicator ml={3} />
                }
              />

              <Route
                path={`${CHARTS}/${INVENTORY}`}
                element={
                  atlasReady ? <InventoryCharts /> : <LoadingIndicator ml={3} />
                }
              />

              <Route
                path={`${CHARTS}/${RATES}`}
                element={
                  atlasReady ? <RatesCharts /> : <LoadingIndicator ml={3} />
                }
              />

              <Route
                path={`${CHARTS}/${OCCUPANCY}`}
                element={
                  atlasReady ? <OccupancyCharts /> : <LoadingIndicator ml={3} />
                }
              />

              <Route
                path={`${CHARTS}/${TREND}`}
                element={
                  atlasReady ? <TrendCharts /> : <LoadingIndicator ml={3} />
                }
              />

              <Route
                path={`${CHARTS}/${REVENUE}`}
                element={
                  atlasReady ? <RevenueCharts /> : <LoadingIndicator ml={3} />
                }
              />

              <Route
                path={`${DYNAMIC_PRICE}/${LISTINGS}`}
                element={<DynamicPrice />}
              >
                <Route
                  path=""
                  element={
                    dynamicPricingReady ? (
                      <PricingDashboard />
                    ) : (
                      <LoadingIndicator ml="28px" />
                    )
                  }
                />

                <Route path=":listingId" element={<ListingPricing />} />
              </Route>

              <Route
                path={`${DYNAMIC_PRICE}/${ACCOUNTS}`}
                element={<ChannelAccounts />}
              />
            </Route>

            <Route path="/blog/:slug" element={<BlogPost />} />
            <Route path="/blog" element={<BlogIndex />} />

            <Route path="/" element={<Home />} />

            {/* <Route path="/investment/:query?" element={<Investment />} /> */}

            {/* <Route path="/analyzer/:query?" element={<Analyzer />} /> */}

            {/* <Route path="/settings" element={<Settings />} /> */}
            <Route path="/faq" element={<FAQ />} />
            <Route path="/contact" element={<ContactUs full />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/policy" element={<PrivacyAndCookiePolicy />} />
            <Route path="/tos" element={<TermsOfService />} />

            {/* <Route path="/labeler" element={<Labeler />} /> */}

            {/* <Route */}
            {/*   path="/test" */}
            {/*   element={ */}
            {/*     <ErrorBoundaryRender error={{ message: "shit" }} /> */}
            {/*   } */}
            {/* /> */}
          </Routes>
        </Suspense>
      </GoogleAnalyticsPageTracker>
    </Router>
  );
};

export default withErrorBoundary(RoutedApp);
