export const BIG_CALENDAR = "BIG_CALENDAR";
export const SIDE_EDIT_PANE = "SIDE_EDIT_PANE";

export const MODE = "mode";
export const ADJUSTMENT_TYPE = "adjustmentType";
export const ADJUSTMENT_SUB_TYPE = "adjustmentSubType";

export const SET_TO = "Set To";
export const INCREASE_BY = "Increase By";
export const DECREASE_BY = "Decrease By";
export const DEFAULT = "Default";
export const FIXED = "Fixed"; // absolute amount
export const FLAT = "Flat"; // percentage
export const GRADUAL = "Gradual"; // percentage
export const DISABLE = "Disable";
export const DISCOUNT = "Discount";
export const PREMIUM = "Premium";
export const CUSTOM = "Custom";

export const LAST_MINUTE = "lastMinute";
export const FAR_FUTURE = "farFuture";
export const ORPHAN_DAY = "orphanDay";
export const DAY_OF_WEEK = "dayOfWeek";
export const OCCUPANCY_ADJUSTMENT = "occupancyAdjustment";

export const translateEnum = (key) => {
  const mapping = {
    SEASONALITY: "Seasonality",
    WEEKLY: "Weekly",
    DEMAND_FACTOR: "Demand",
    LAST_MINUTE: "Last Minute",
    FAR_FUTURE: "Far Future",
    ORPHAN_DAY: "Orphan Day",
    DAY_OF_WEEK: "Day of Week",
    OCCUPANCY_ADJUSTMENT: "Occupancy Adjustment",
    DATE_OVERRIDE_ADJUSTMENT: "Override Adjustment",
    MIN_PRICE: "Min Price",
    MAX_PRICE: "Max Price",
    OVERRIDE_PRICE: "Override Price",
  };

  return mapping[key];
};
