import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { logPage } from "../util/analytics";

export const GoogleAnalyticsPageTracker = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    logPage(location);
  }, [location]);

  return <>{children}</>;
};
