import React from "react";
import { Box, Container, Typography, Paper, Alert } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";

const ErrorBoundaryRender = ({ error }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        padding: "16px",
      }}
    >
      <Paper
        elevation={3}
        sx={{
          p: 3,
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{ textAlign: "center" }}>
          <SentimentVeryDissatisfiedIcon sx={{ fontSize: 32, mb: 1 }} />
          <Typography
            sx={{ mb: 4 }}
            variant="body1"
            color="text.secondary"
            paragraph
          >
            Oh shizzles, but an unexpected error occurred while rendering this
            component. Please try refreshing the page or contact support if the
            problem persists.
          </Typography>
          <Box sx={{ bgcolor: "grey.100", p: 2, borderRadius: 1 }}>
            <Typography
              variant="body2"
              component="pre"
              sx={{
                fontFamily: "monospace",
                whiteSpace: "pre-wrap",
                color: "error.main",
                fontSize: "0.75rem",
              }}
            >
              {error && error.message}
            </Typography>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default ErrorBoundaryRender;
