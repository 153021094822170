var bristleback_host = undefined;

if (ENV === "production") {
  bristleback_host = "https://bristleback.airroi.com";
} else if (ENV === "minikube") {
  bristleback_host = "https://bristleback.airroi.local";
} else if (ENV === "development") {
  if (BRISTLEBACK != null) {
    // npm start -- --env bristleback=http://192.168.50.28:9080, this allows remote testing. e.g. from mobile device within LAN
    bristleback_host = BRISTLEBACK;
  } else {
    bristleback_host = "http://localhost:9080";
  }
} else {
  throw new Error("ENV is undefined, please check webpack config!");
}

export const API_HOST = bristleback_host;
export const CHROME_EXTENSION_URL =
  "https://chromewebstore.google.com/detail/airroi-connect/mekobonkeiafgdflgfciieaalhgkiicp";
