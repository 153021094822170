import {
  GET_DASHBOARD_LISTINGS,
  GET_LISTING,
  GET_LISTING_RULES,
  SYNC_LISTING_NOW,
  UPDATE_LISTING,
  UPDATE_LISTING_RULES,
  IMPORT_NEW_LISTINGS,
  UPDATE_DASHBOARD_QUERY,
  SELECT_LISTING_MONTH,
  SET_LISTING_DATA_READINESS,
  SET_DASHBOARD_DATA_READINESS,
  GET_CHANNEL_ACCOUNTS,
  GET_BASE_PRICE_RECOMMENDATIONS,
  SET_LISTING_SETTINGS_DRAWER_VISIBILITY,
} from "../actions/dynamicPricingActions";
import { DAY_OF_WEEK, OCCUPANCY_ADJUSTMENT } from "../constants/dynamicPricing";

const transformRules = (rules) => {
  const rulesObj = rules.reduce((acc, item) => {
    const key = item.type;
    acc[key] = item;

    return acc;
  }, {});

  return {
    ...rulesObj,
    [OCCUPANCY_ADJUSTMENT]: {
      ...rulesObj[OCCUPANCY_ADJUSTMENT],
      values: Object.entries(rulesObj[OCCUPANCY_ADJUSTMENT].values),
    },
    [DAY_OF_WEEK]: {
      ...Object.fromEntries(
        Object.entries(rulesObj[DAY_OF_WEEK]).map(([k, v]) => [
          k,
          v == null ? "" : v,
        ])
      ), // convert null to empty string so that react doesn't complain about uncontrolled text-field component
    },
  };
};
const dynamicPricingReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_LISTING:
      return {
        ...state,
        listing: {
          ready: true,
          channel: action.channel,
          listingId: action.listingId,
          referenceId: action.referenceId,
          autoSync: action.autoSync,
          groupId: action.groupId,
          basePrice: action.basePrice,
          minPrice: action.minPrice,
          maxPrice: action.maxPrice,
          listingInfo: {
            fallbackBasePrice: action.listingInfo.fallbackBasePrice,
            currency: action.listingInfo.currency,
            sevenDaysOccupancy: action.listingInfo.sevenDaysOccupancy,
            thirtyDaysOccupancy: action.listingInfo.thirtyDaysOccupancy,
            sixtyDaysOccupancy: action.listingInfo.sixtyDaysOccupancy,
            latitude: action.listingInfo.latitude,
            longitude: action.listingInfo.longitude,
            entireHome: action.listingInfo.entireHome,
            bedrooms: action.listingInfo.bedrooms,
            guests: action.listingInfo.guests,
            baths: action.listingInfo.baths,
          },
          overrides: action.overrides,
          listingRules: transformRules(action.listingRules),
          appliedRules: transformRules(action.appliedRules),
          defaultRules: transformRules(action.defaultRules),
          rates: action.rates,
        },
      };
    case GET_DASHBOARD_LISTINGS:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          ready: true,
          demo: action.demo,
          listings: action.listings,
        },
      };
    case UPDATE_DASHBOARD_QUERY:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          query: action.query,
        },
      };
    case SELECT_LISTING_MONTH:
      return {
        ...state,
        listing: {
          ...state.listing,
          selectedMonth: action.selectedMonth,
        },
      };
    case SET_LISTING_DATA_READINESS:
      return {
        ...state,
        listing: {
          ...state.listing,
          ready: action.ready,
        },
      };
    case SET_DASHBOARD_DATA_READINESS:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          ready: action.ready,
        },
      };
    case GET_CHANNEL_ACCOUNTS:
      return {
        ...state,
        accounts: action.accounts,
      };
    case GET_BASE_PRICE_RECOMMENDATIONS:
      return {
        ...state,
        listing: {
          ...state.listing,
          basePriceRecommendations: {
            conservative: action.conservative,
            balanced: action.balanced,
            aggressive: action.aggressive,
          },
        },
      };
    case SET_LISTING_SETTINGS_DRAWER_VISIBILITY:
      return {
        ...state,
        listing: {
          ...state.listing,
          drawerVisible: action.visible,
        },
      };

    default:
      return state;
  }
};

export default dynamicPricingReducer;
