import { RECEIVE_FOR_SALE_LISTINGS } from "../actions/airbnbListingsActions";

const forSaleListingsReducer = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_FOR_SALE_LISTINGS:
      return {
        latitude: action.latitude,
        longitude: action.longitude,
        listings: action.listings.map((listing) => ({
          listingId: listing.id,
          latitude: listing.latitude,
          longitude: listing.longitude,
          photoURL: listing.photo_url,
          beds: listing.beds,
          baths: listing.baths,
          addressStreet: listing.address_street,
          addressCity: listing.address_city,
          addressState: listing.address_state,
          addressZipCode: listing.address_zip_code,
          livingArea: listing.living_area,
          lotSize: listing.lot_size,
          listingPrice: listing.listing_price,
          estimatePrice: listing.estimate_price,
          estimateRent: listing.estimate_rent,
        })),
      };
    default:
      return state;
  }
};

export default forSaleListingsReducer;
