import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserSession,
} from "amazon-cognito-identity-js";
import { REFRESH_TOKEN_LEAD_TIME } from "../../constants/auth";
import { deleteCookie, setCookie } from "../../util/jsutil";

const poolData = {
  UserPoolId: "us-west-2_uqxPG9T8t", // airroi pool id
  ClientId: "jhd7pk12k6927ks67phqa98iq", // airroi client id
};

const userPool = new CognitoUserPool(poolData);

export const logIn = (email, password) => {
  const authenticationData = {
    Username: email,
    Password: password,
  };

  const authenticationDetails = new AuthenticationDetails(authenticationData);

  const userData = {
    Username: email,
    Pool: userPool,
  };

  const cognitoUser = new CognitoUser(userData);

  return new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess(result) {
        resolve(result);
      },

      onFailure: (err) => {
        reject(err);
      },
    });
  });
};

export const logOut = () => {
  const cognitoUser = userPool.getCurrentUser();
  if (cognitoUser != null) {
    cognitoUser.signOut();
  }
};

// note that we currently have a lambda trigger to auto-confirm user: "cognito-auto-confirm-user""
export const signUp = (firstName, lastName, email, password) => {
  // note: the attributes must be writable as configured in AWS Cognito
  const attributeList = [];

  const dataFirstName = {
    Name: "given_name",
    Value: firstName,
  };
  const attributeFirstName = new CognitoUserAttribute(dataFirstName);
  attributeList.push(attributeFirstName);

  const dataLastName = {
    Name: "family_name",
    Value: lastName,
  };
  const attributeLastName = new CognitoUserAttribute(dataLastName);
  attributeList.push(attributeLastName);

  return new Promise((resolve, reject) => {
    userPool.signUp(email, password, attributeList, null, (err, _result) => {
      if (err) {
        reject(err);
      }
      resolve(); // still need to resolve empty here, otherwise outside promise then() method cannot trigger
    });
  });
};

export const forgotPassword = (email) => {
  const userData = {
    Username: email,
    Pool: userPool,
  };

  const cognitoUser = new CognitoUser(userData);
  return new Promise((resolve, reject) => {
    cognitoUser.forgotPassword({
      onSuccess: (data) => {
        resolve(data);
      },
      onFailure: (err) => {
        reject(err);
      },
    });
  });
};

export const confirmPassword = (email, verificationCode, newPassword) => {
  const userData = {
    Username: email,
    Pool: userPool,
  };

  const cognitoUser = new CognitoUser(userData);

  return new Promise((resolve, reject) => {
    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onSuccess() {
        resolve();
      },
      onFailure(err) {
        reject(err);
      },
    });
  });
};

export const getSession = () => {
  const cognitoUser = userPool.getCurrentUser();

  if (cognitoUser != null) {
    return new Promise((resolve, reject) => {
      // getSession will internally refresh session if current session has expired
      cognitoUser.getSession((err, session) => {
        if (err) {
          reject(err);
        } else {
          resolve(session);
        }
      });
    });
  }

  return Promise.reject(new Error("No existing user session."));
};

// sometimes we want to force a session refresh even if the current session is valid, e.g. after subscription modifications
export const refreshSession = (forceRefresh) => {
  const cognitoUser = userPool.getCurrentUser();
  if (cognitoUser == null) {
    return Promise.reject(new Error("No existing user session."));
  }
  return new Promise((resolve, reject) => {
    getSession()
      .then((prevSession) => {
        const now = Math.floor(new Date() / 1000);
        const adjusted = now - prevSession.getClockDrift();

        if (
          adjusted + REFRESH_TOKEN_LEAD_TIME >
            prevSession.getAccessToken().getExpiration() ||
          adjusted + REFRESH_TOKEN_LEAD_TIME >
            prevSession.getIdToken().getExpiration() ||
          forceRefresh
        ) {
          // TODO: handle refresh token expires, which is 10 years set by AWS Cognito user pool config
          cognitoUser.refreshSession(
            prevSession.getRefreshToken(),
            (err, session) => {
              if (err) {
                reject(err);
              }

              // note that Promise.resolve() does not terminate the function, if we don't have else block, it would continue to execute rest of the function
              // https://stackoverflow.com/questions/28896280/why-does-javascript-es6-promises-continue-execution-after-a-resolve
              resolve(session);
            }
          );
        } else {
          resolve(prevSession);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const dropIdTokenAsCookie = (idToken) => {
  setCookie("airroiToken", idToken);
};

export const deleteIdToken = () => {
  deleteCookie("airroiToken");
};
