import React from "react";
import ErrorBoundary from "./ErrorBoundary";

function withErrorBoundary(WrappedComponent, errorBoundaryProps = {}) {
  return class extends React.Component {
    render() {
      return (
        <ErrorBoundary {...errorBoundaryProps}>
          <WrappedComponent {...this.props} />
        </ErrorBoundary>
      );
    }
  };
}

export default withErrorBoundary;
