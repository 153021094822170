import { combineReducers } from "redux";
import airbnbListingsReducer from "./airbnbListingsReducer";
import dynamicPricingReducer from "./dynamicPricingReducer";
import forSaleListingsReducer from "./forSaleListingsReducer";
import forSaleListingReducer from "./forSaleListingReducer";
import sessionReducer from "./sessionReducer";
import toastReducer from "./toastReducer";

export default combineReducers({
  toast: toastReducer,
  session: sessionReducer,
  airbnbListings: airbnbListingsReducer,
  dynamicPricing: dynamicPricingReducer,
  forSaleListings: forSaleListingsReducer,
  forSaleListing: forSaleListingReducer,
});
