import {
  RECEIVE_AIRBNB_LISTINGS_DETAILS,
  RECEIVE_AIRBNB_LISTINGS,
  UPDATE_LOCATION,
  UPDATE_FILTERS,
  UPDATE_SORT_ORDER,
  UPDATE_GOOGLEMAP_VIEW,
  UPDATE_CURRENCY,
  RESET_AIRBNB_LISTINGS,
  SET_ATLAS_DEMO_FLAG,
  SET_QUERY_DATA_READINESS,
} from "../actions/airbnbListingsActions";

import defaultStoreValues from "../store/storeDefaultValues";

const airbnbListingsReducer = (state = {}, action) => {
  switch (action.type) {
    case RESET_AIRBNB_LISTINGS:
      return { ...defaultStoreValues.airbnbListings };
    case SET_ATLAS_DEMO_FLAG:
      return {
        ...state,
        demo: action.demo,
      };
    case RECEIVE_AIRBNB_LISTINGS_DETAILS:
      return {
        ...state,
        ready: true,
        listingsDetails: {
          ...state.listingsDetails,
          ...action.listings.reduce((acc, listing) => {
            acc[listing.id] = {
              detail: true,
              id: listing.id,
              listingId: listing.listing_id,
              latitude: listing.latitude,
              longitude: listing.longitude,
              state: listing.state,
              zipCode: listing.zip_code,
              revenue: listing.revenue,
              daysOccupied: listing.days_occupied,
              photoURL: listing.cover_photo_url,
              bedrooms: listing.bedrooms,
              listingType: listing.listing_type,
              occupancyRate: listing.occupancy_rate,
              listingName: listing.listing_name,
              countryCode: listing.country_code,
              guests: listing.guests,
              beds: listing.beds,
              baths: listing.baths,
              numReviews: listing.num_reviews,
              minNights: listing.min_nights,
              starRating: listing.star_rating,
              averageDailyRate: listing.average_daily_rate,
              trailing: listing.trailing_twelve_months,
            };
            return acc;
          }, {}),
        },
      };
    case RECEIVE_AIRBNB_LISTINGS:
      return {
        ...state,
        ready: true,
        demo: action.demo,
        nativeCurrency: action.native_currency,
        listingsDetails: {},
        listings: action.listings.map((listing) => ({
          detail: false,
          id: listing.id,
          latitude: listing.latitude,
          longitude: listing.longitude,
        })),
        totalListings: action.listings.length,
        googleMap: {
          latitude: action.latitude,
          longitude: action.longitude,
        },
        aggregates: {
          days: action.daily_data.map((daily) => ({
            date: daily.date,
            booked: daily.booked,
            available: daily.available,
            occupancy: daily.occupancy,
            bookedRateAverage: daily.booked_rate_average,
            bookedRate25th: daily.booked_rate25th,
            bookedRate75th: daily.booked_rate75th,
            demand: daily.demand,
            availableRateAverage: daily.available_rate_average,
            availableRate25th: daily.available_rate25th,
            availableRate75th: daily.available_rate75th,
            bookingTrend: daily.booking_trend,
          })),
          months: action.monthly_data.map((monthly) => ({
            date: monthly.date,
            pricePercentiles: monthly.price_percentiles,
            priceAvg: monthly.price_avg,
            occupancyPercentiles: monthly.occupancy_percentiles,
            occupancyAvg: monthly.occupancy_avg,
            activeListings: monthly.active_listings,
            bookedListings: monthly.booked_listings,
            nights: monthly.nights,
            revenue: monthly.revenue,
            revenueAvg: monthly.revenue_avg,
            revenuePercentiles: monthly.revenue_percentiles,
            bookingLeadTimeBreakdown: monthly.booking_lead_time_breakdown,
            averageBookingLeadTime: monthly.average_booking_lead_time,
          })),
          availableDaysBreakdown: action.available_days_breakdown,
          unavailableDaysBreakdown: action.unavailable_days_breakdown,
          listingTypeBreakdown: action.listing_type_breakdown,
          roomCapacityBreakdown: action.room_capacity_breakdown,
          guestCapacityBreakdown: action.guest_capacity_breakdown,
          cancellationPolicyBreakdown: action.cancellation_policy_breakdown,
          minNightsBreakdown: action.min_nights_breakdown,
          topAmenities: action.top_amenities,
          ratingsAverage: action.ratings_average,
        },
        query: {
          ...state.query,
          location: {
            countryCode: action.location.country_code,
            state: action.location.state,
            city: action.location.city,
            neighborhood: action.location.neighborhood,
            subdivision: action.location.subdivision,
          },
          currency:
            action.native_currency !== action.currency ? action.currency : null, // only set currency if it's not default for that region, to avoid double api calls
        },
      };
    case UPDATE_LOCATION:
      return {
        ...state,
        query: {
          ...state.query,
          location: {
            countryCode: action.location.countryCode,
            state: action.location.state,
            city: action.location.city,
            neighborhood: action.location.neighborhood,
            subdivision: action.location.subdivision,
          },
          currency: action.location.nativeCurrency,
        },
      };
    case UPDATE_FILTERS:
      return {
        ...state,
        query: {
          ...state.query,
          filters: action.filters,
        },
      };
    case UPDATE_SORT_ORDER:
      return {
        ...state,
        query: {
          ...state.query,
          sortOrder: action.sortOrder,
        },
      };
    case UPDATE_CURRENCY:
      return {
        ...state,
        query: {
          ...state.query,
          currency: action.currency,
        },
      };
    case UPDATE_GOOGLEMAP_VIEW:
      return {
        ...state,
        googleMap: {
          latitude: action.lat,
          longitude: action.lng,
          zoom: action.zoom,
        },
      };
    case SET_QUERY_DATA_READINESS:
      return {
        ...state,
        ready: action.ready,
      };
    default:
      return state;
  }
};

export default airbnbListingsReducer;
