export const ATLAS = "atlas";
export const INVESTMENT = "investment";
export const ANALYZER = "analyzer";
export const SETTINGS = "settings";
export const HOME = "home";

// these nodeIds are also used as path for react router
export const MAP = "map";
export const CHARTS = "charts";
export const INVENTORY = "inventory";
export const RATES = "rates";
export const SEASONALITY = "seasonality";
export const TREND = "trend";
export const OCCUPANCY = "occupancy";
export const REVENUE = "revenue";
export const DYNAMIC_PRICE = "dynamic-price";
export const LISTINGS = "listings";
export const ACCOUNTS = "accounts";

export const CHARTS_PAGES = [
  INVENTORY,
  RATES,
  SEASONALITY,
  TREND,
  OCCUPANCY,
  REVENUE,
];

export const DYNAMIC_PRICE_PAGES = [LISTINGS, ACCOUNTS];
