/* eslint-disable react/prop-types */
import {
  Box,
  Modal,
  Avatar,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  TextField,
  Typography,
  Paper,
  Alert,
} from "@mui/material";
import React, { useState } from "react";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { useDispatch, useSelector } from "react-redux";
import {
  clearToastAction,
  confirmPasswordAction,
  CONFIRM_PASSWORD,
  forgotPasswordAction,
  FORGOT_PASSWORD,
  hideAuthModalAction,
  logInAction,
  LOG_IN,
  showAuthErrorAction,
  showConfirmPasswordModalAction,
} from "../../actions/airbnbListingsActions";
import Toast from "../Toast";
import {
  CONFIRM_PASSWORD_MODAL,
  FORGOT_PASSWORD_MODAL,
} from "../../constants/auth";

/**
 * combines two forms: Forgot Password and Confirm Password
 */
const ResetPassword = () => {
  const dispatch = useDispatch();
  const error = useSelector((state) => state.session.error);
  const isForgotPasswordOpen = useSelector(
    (state) => state.session.modal === FORGOT_PASSWORD_MODAL
  );
  const isConfirmPasswordOpen = useSelector(
    (state) => state.session.modal === CONFIRM_PASSWORD_MODAL
  );

  const formDefault = {
    email: "",
    verificationCode: "",
    newPassword: "",
    confirmPassword: "",
  };
  const [formValues, setFormValues] = useState(formDefault);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmitForgotPassword = (event) => {
    event.preventDefault();
    dispatch(forgotPasswordAction(formValues.email));
  };

  const handleSubmitConfirmPassword = (event) => {
    event.preventDefault();

    if (formValues.newPassword !== formValues.confirmPassword) {
      dispatch(showAuthErrorAction("Passwords do not match."));
    } else {
      dispatch(
        confirmPasswordAction(formValues, () => setFormValues(formDefault))
      );
    }
  };

  return (
    <>
      <Modal
        open={isForgotPasswordOpen}
        onClose={() => {
          setFormValues(formDefault);
          dispatch(hideAuthModalAction());
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
        >
          <Paper sx={{ p: 4, width: 400 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
                <AccountBoxIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Reset Password
              </Typography>
              <Typography variant="body2" sx={{ mt: 1 }}>
                Enter the email associated with your account and we will send a
                verification code to reset your password.
              </Typography>
              <Box
                component="form"
                width="100%"
                noValidate
                onSubmit={handleSubmitForgotPassword}
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  required
                  size="small"
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={formValues.email}
                  onChange={handleInputChange}
                />

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Send Code
                </Button>

                {error != null && (
                  <Alert sx={{ mt: 2 }} severity="error">
                    {error}
                  </Alert>
                )}
              </Box>
            </Box>
          </Paper>
        </Box>
      </Modal>

      <Modal
        open={isConfirmPasswordOpen}
        onClose={() => {
          setFormValues(formDefault);
          dispatch(hideAuthModalAction());
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
        >
          <Paper sx={{ p: 4, width: 400 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
                <AccountBoxIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Reset Password
              </Typography>
              <Typography variant="body2" sx={{ mt: 1 }}>
                We have sent a verification code to your email. Enter it below
                to reset your password.
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmitConfirmPassword}
                sx={{ mt: 1 }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      margin="normal"
                      required
                      size="small"
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      autoFocus
                      value={formValues.email}
                      onChange={handleInputChange}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      margin="normal"
                      required
                      size="small"
                      fullWidth
                      id="verificationCode"
                      label="Verification Code"
                      name="verificationCode"
                      autoFocus
                      value={formValues.verificationCode}
                      onChange={handleInputChange}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="newPassword"
                      label="New Password"
                      type="password"
                      id="newPassword"
                      size="small"
                      value={formValues.newPassword}
                      onChange={handleInputChange}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="confirmPassword"
                      label="Confirm Password"
                      type="password"
                      id="confirmPassword"
                      size="small"
                      value={formValues.confirmPassword}
                      onChange={handleInputChange}
                    />
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Change Password
                </Button>

                {error != null && (
                  <Alert sx={{ mt: 2 }} severity="error">
                    {error}
                  </Alert>
                )}
              </Box>
            </Box>
          </Paper>
        </Box>
      </Modal>
    </>
  );
};

ResetPassword.propTypes = {};

ResetPassword.defaultProps = {};

export default ResetPassword;
