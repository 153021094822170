import React from "react";
import HomeOutlined from "@mui/icons-material/HomeOutlined";

const EntireHome = () => {
  return (
    <HomeOutlined
      sx={{
        fontSize: "18px",
        // color: "white",
        // backgroundColor: "#FF5A5F",
        borderRadius: "50%",
        verticalAlign: "middle",
      }}
    />
  );
};

export default EntireHome;
