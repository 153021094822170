export const logPage = (location) => {
  if (typeof window.gtag === "function") {
    window.gtag("event", "page_view", {
      page_location: window.location.href,
      page_path: location.pathname + location.search,
      page_title: document.title,
    });
  }
};

export const logEvent = (eventName, eventParams) => {
  if (typeof window.gtag === "function") {
    window.gtag("event", eventName, eventParams);
  }
};
