import { SET_TOAST, CLEAR_TOAST } from "../actions/toastActions";

const toastReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_TOAST:
      return {
        ...state,
        level: action.level,
        message: action.message,
      };
    case CLEAR_TOAST:
      return {
        ...state,
        level: action.level,
        message: action.message,
      };
    default:
      return state;
  }
};

export default toastReducer;
