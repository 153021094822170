/* eslint-disable react/prop-types */
import React, { useCallback } from "react";

import { Snackbar, Alert, Paper } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "../util/jsutil";
import { clearToast } from "../actions/toastActions";

const Toast = () => {
  const dispatch = useDispatch();
  const toast = useSelector((state) => state.toast);

  const handleClose = useCallback(() => {
    dispatch(clearToast());
  });

  return (
    !isEmpty(toast.level) && ( // get rid of warning on severity not accepting empty string
      <Snackbar
        open={!isEmpty(toast.level)}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Paper elevation={1}>
          <Alert
            onClose={handleClose}
            severity={toast.level}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {toast.message}
          </Alert>
        </Paper>
      </Snackbar>
    )
  );
};

export default Toast;
