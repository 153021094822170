import { createTheme } from "@mui/material/styles";

// All the following keys are optional, as default values are provided.
const theme = createTheme({
  typography: {
    fontFamily: `"Lato", "sans-serif"`, // default is Roboto, which needs to be imported in index.html <link> from google fonts
    h7: {
      fontWeight: 500,
      fontSize: "0.875rem",
      lineHeight: "1.5rem",
      letterSpacing: "0.01071em",
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          // currently, need to provide all of the default mapping because it will replace, not merge.
          // this is need to get margin to work for customized typography
          h1: "h1",
          h2: "h2",
          h3: "h3",
          h4: "h4",
          h5: "h5",
          h6: "h6",
          subtitle1: "h6",
          subtitle2: "h6",
          body1: "p",
          body2: "p",
          inherit: "p",
          // have to add this line
          // otherwise the `span` (display `inline` by default)
          // cannot have margin.
          h7: "p",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "0.8rem",
        },
      },
    },
  },
  palette: {
    background: {
      default: "#fafafa",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      tinyMobile: 400,
      miniMobile: 500,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      hd: 1920,
      qhd: 2560,
      uhd: 3840,
      max: 7680,
    },
  },
});

export default theme;
