/* eslint-disable react/prop-types */
import {
  Box,
  Modal,
  Avatar,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  TextField,
  Typography,
  Paper,
  Alert,
} from "@mui/material";
import React, { useState } from "react";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { useDispatch, useSelector } from "react-redux";
import {
  clearToastAction,
  logInAction,
  LOG_IN,
  showForgotPasswordModalAction,
  showSignUpModalAction,
} from "../../actions/airbnbListingsActions";

const Login = ({ isOpen, closeModal }) => {
  const dispatch = useDispatch();
  const error = useSelector((state) => state.session.error);

  const formDefault = {
    email: "",
    password: "",
  };
  const [formValues, setFormValues] = useState(formDefault);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(
      logInAction(
        { email: formValues.email, password: formValues.password },
        () => setFormValues(formDefault)
      )
    );
  };

  return (
    <>
      <Modal
        open={isOpen}
        onClose={() => {
          setFormValues(formDefault);
          closeModal();
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
        >
          <Paper sx={{ p: 4, width: 400 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
                <AccountBoxIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Log in
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  required
                  size="small"
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={formValues.email}
                  onChange={handleInputChange}
                />
                <TextField
                  margin="normal"
                  required
                  size="small"
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={formValues.password}
                  onChange={handleInputChange}
                />
                {/* <FormControlLabel */}
                {/*   control={<Checkbox value="remember" color="primary" />} */}
                {/*   label="Remember me" */}
                {/* /> */}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Log In
                </Button>
                <Grid container>
                  <Grid item xs>
                    <Button
                      variant="text"
                      size="small"
                      sx={{
                        textTransform: "none",
                        textDecoration: "underline",
                      }}
                      onClick={() => {
                        dispatch(showForgotPasswordModalAction());
                      }}
                    >
                      Forgot password?
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="text"
                      size="small"
                      sx={{
                        textTransform: "none",
                        textDecoration: "underline",
                      }}
                      onClick={() => {
                        dispatch(showSignUpModalAction());
                      }}
                    >
                      Don't have an account? Sign Up
                    </Button>
                  </Grid>
                </Grid>

                {error != null && (
                  <Alert sx={{ mt: 2 }} severity="error">
                    {error}
                  </Alert>
                )}
              </Box>
            </Box>
          </Paper>
        </Box>
      </Modal>
    </>
  );
};

Login.propTypes = {};

Login.defaultProps = {};

export default Login;
