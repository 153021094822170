export const APP_BAR_HEIGHT = "50px";
export const ATLAS_MENU_HEIGHT = "60px";
export const ATLAS_ALL_HEADERS_HEIGHT = "110px";
export const ATLAS_ALL_HEADERS_MOBILE_HEIGHT = "166px";

export const DIVIDER_HEIGHT = "28px"; // 8px height + 20px margin

export const ATLAS_ALL_HEADERS_AND_DIVIDER = "138px";

export const MARGIN_BELOW_ATLAS_MENU = "6px";
export const MARKET_CARDS_HEIGHT = "150";
export const GOOGLE_MAPS_ABOVE_SECTIONS_WITH_CARDS_HEIGHT = "294px";
export const GOOGLE_MAPS_ABOVE_SECTIONS_MOBILE_WITHOUT_CARDS_HEIGHT = "174px";
export const GOOGLE_MAPS_ABOVE_SECTIONS_WITHOUT_CARDS_HEIGHT = "138px"; // 6px difference due to margin below atlas menu

export const SIDEBAR_WIDTH = "200px";
export const SIDEBAR_HEIGHT = "500px";

export const disableNumericFieldArrows = {
  '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button':
    {
      WebkitAppearance: "none",
      margin: 0,
    },
  '& input[type="number"]': {
    mozAppearance: "textfield",
  },
};

// derived from medium.com through ChatGPT, breakpoints and derived responsive properties added by Claude
export const markDownStyles = {
  // General styles
  "& h1 + p em:first-child": {
    // gives published on: date more space
    display: "block",
    marginBottom: {
      xs: "24px",
      sm: "32px",
      md: "48px",
    },
  },
  "& img": {
    maxWidth: "100%",
    height: "auto",
    display: "block",
    margin: "0 auto", // This centers the image
  },
  fontFamily: "Charter, serif",
  lineHeight: 1.58,
  color: "#333",
  fontSize: {
    xs: "16px",
    sm: "17px",
    md: "18px",
  },
  backgroundColor: "#fff",
  // display
  display: "flex",
  maxWidth: "100%",
  justifyContent: "center",
  // Title
  h1: {
    fontFamily: "Charter, serif",
    fontSize: {
      xs: "24px",
      sm: "28px",
      md: "32px",
    },
    fontWeight: 700,
    lineHeight: 1.2,
    mt: {
      xs: "16px",
      sm: "32px",
      md: "80px",
    },
    mb: {
      xs: "16px",
      sm: "32px",
      md: "100px", // Increased from 80px
    },
    textAlign: "center", // Center the title
    width: "100%", // Ensure the title takes full width
  },

  // Headings
  h2: {
    fontFamily: "Charter, serif",
    fontSize: {
      xs: "18px",
      sm: "19px",
      md: "20px",
    },
    fontWeight: 700,
    mt: {
      xs: 2,
      sm: 3,
      md: 5, // Increased from 4
    },
    mb: {
      xs: 1,
      sm: 1.5,
      md: 3, // Increased from 2
    },
  },
  h3: {
    fontFamily: "Charter, serif",
    fontSize: {
      xs: "16px",
      sm: "17px",
      md: "18px",
    },
    fontWeight: 700,
    mt: {
      xs: 2,
      sm: 3,
      md: 5, // Increased from 4
    },
    mb: {
      xs: 1,
      sm: 1.5,
      md: 3, // Increased from 2
    },
  },
  h4: {
    fontFamily: "Charter, serif",
    fontSize: {
      xs: "14px",
      sm: "15px",
      md: "16px",
    },
    fontWeight: 700,
    mt: {
      xs: 2,
      sm: 3,
      md: 5, // Increased from 4
    },
    mb: {
      xs: 1,
      sm: 1.5,
      md: 3, // Increased from 2
    },
  },
  h5: {
    fontFamily: "Charter, serif",
    fontSize: {
      xs: "12px",
      sm: "13px",
      md: "14px",
    },
    fontWeight: 700,
    mt: {
      xs: 2,
      sm: 3,
      md: 5, // Increased from 4
    },
    mb: {
      xs: 1,
      sm: 1.5,
      md: 3, // Increased from 2
    },
  },
  h6: {
    fontFamily: "Charter, serif",
    fontSize: {
      xs: "10px",
      sm: "11px",
      md: "12px",
    },
    fontWeight: 700,
    mt: {
      xs: 2,
      sm: 3,
      md: 5, // Increased from 4
    },
    mb: {
      xs: 1,
      sm: 1.5,
      md: 3, // Increased from 2
    },
  },
  // Paragraphs
  p: {
    mt: 0,
    mb: {
      xs: 1.5,
      sm: 2,
      md: 4, // Increased from 3
    },
  },
  // Code blocks
  pre: {
    backgroundColor: "#f6f8fa",
    p: {
      xs: 1,
      sm: 1.5,
      md: 2,
    },
    overflowX: "auto",
    borderRadius: "3px",
    fontSize: {
      xs: "12px",
      sm: "13px",
      md: "14px",
    },
  },
  code: {
    fontFamily: "Roboto Mono, monospace",
    fontSize: {
      xs: "12px",
      sm: "13px",
      md: "14px",
    },
  },
  // Lists
  ul: {
    mt: 0,
    mb: {
      xs: 1.5,
      sm: 2,
      md: 4, // Increased from 3
    },
    pl: {
      xs: "24px",
      sm: "32px",
      md: "40px",
    },
  },
  ol: {
    mt: 0,
    mb: {
      xs: 1.5,
      sm: 2,
      md: 4, // Increased from 3
    },
    pl: {
      xs: "24px",
      sm: "32px",
      md: "40px",
    },
  },
  li: {
    mb: {
      xs: 0.5,
      sm: 0.75,
      md: 1.5, // Increased from 1
    },
  },
  // Hyperlinks
  a: {
    color: "#0077cc",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  // Other elements
  blockquote: {
    borderLeft: "4px solid #ccc",
    pl: {
      xs: 1,
      sm: 1.5,
      md: 2,
    },
    mt: 0,
    mb: {
      xs: 1.5,
      sm: 2,
      md: 4, // Increased from 3
    },
  },
  hr: {
    border: "none",
    borderTop: "1px solid #ccc",
    mt: {
      xs: 2,
      sm: 3,
      md: 5, // Increased from 4
    },
    mb: {
      xs: 2,
      sm: 3,
      md: 5, // Increased from 4
    },
  },
  // Tables
  table: {
    width: "100%",
    borderCollapse: "collapse",
    mb: {
      xs: 2,
      sm: 3,
      md: 5, // Increased from 4
    },
    fontSize: {
      xs: "14px",
      sm: "15px",
      md: "16px",
    },
  },
  thead: {
    backgroundColor: "#f6f8fa",
  },
  th: {
    border: "1px solid #ccc",
    p: {
      xs: 1,
      sm: 1.5,
    },
    textAlign: "left",
    fontWeight: 700,
    whiteSpace: "nowrap",
  },
  td: {
    border: "1px solid #ccc",
    p: {
      xs: 1,
      sm: 1.5,
    },
    textAlign: "left",
  },
  "tr:nth-of-type(even)": {
    backgroundColor: "#f8f8f8",
  },
};
