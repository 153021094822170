import { createStore, applyMiddleware } from "redux";
import {
  composeWithDevTools,
  composeWithDevToolsDevelopmentOnly,
} from "@redux-devtools/extension";
import thunkMiddleware from "redux-thunk";
import rootReducer from "../reducers/rootReducer";

export default function configureStore(preloadedState) {
  const composeEnhancers = composeWithDevToolsDevelopmentOnly({
    // Specify here name, actionsDenylist, actionsCreators and other options
  });

  return createStore(
    rootReducer,
    preloadedState,
    composeEnhancers(applyMiddleware(thunkMiddleware))
  );
}
