/* eslint-disable react/prop-types */
import {
  Box,
  Modal,
  Avatar,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  TextField,
  Typography,
  Paper,
  Alert,
} from "@mui/material";
import React, { useState } from "react";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { useDispatch, useSelector } from "react-redux";
import {
  showAuthErrorAction,
  showLogInModalAction,
  signUpAction,
  SIGN_UP,
} from "../../actions/airbnbListingsActions";
import Toast from "../Toast";

function SignUp({ isOpen, closeModal }) {
  const dispatch = useDispatch();
  const error = useSelector((state) => state.session.error);

  const formDefault = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  };
  const [formValues, setFormValues] = useState(formDefault);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (formValues.password !== formValues.confirmPassword) {
      dispatch(showAuthErrorAction("Passwords do not match."));
    } else {
      dispatch(signUpAction(formValues, () => setFormValues(formDefault)));
    }
  };

  return (
    <>
      <Modal
        open={isOpen}
        onClose={() => {
          setFormValues(formDefault);
          closeModal();
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
        >
          <Paper sx={{ p: 4, width: 400 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
                <AccountBoxIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Sign Up
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 3 }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="given-name"
                      name="firstName"
                      required
                      fullWidth
                      id="firstName"
                      label="First Name"
                      autoFocus
                      size="small"
                      value={formValues.firstName}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      id="lastName"
                      label="Last Name"
                      name="lastName"
                      autoComplete="family-name"
                      size="small"
                      value={formValues.lastName}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      size="small"
                      value={formValues.email}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="new-password"
                      size="small"
                      value={formValues.password}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="confirmPassword"
                      label="Confirm Password"
                      type="password"
                      id="confirmPassword"
                      autoComplete="new-password"
                      size="small"
                      value={formValues.confirmPassword}
                      onChange={handleInputChange}
                    />
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Sign Up
                </Button>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Button
                      variant="text"
                      size="small"
                      sx={{
                        textTransform: "none",
                        textDecoration: "underline",
                      }}
                      onClick={() => {
                        dispatch(showLogInModalAction());
                      }}
                    >
                      Already have an account? Sign in
                    </Button>
                  </Grid>
                </Grid>

                {error != null && (
                  <Alert sx={{ mt: 2 }} severity="error">
                    {error}
                  </Alert>
                )}
              </Box>
            </Box>
          </Paper>
        </Box>
      </Modal>
    </>
  );
}

SignUp.propTypes = {};

SignUp.defaultProps = {};

export default SignUp;
