import { ENTIRE_HOME, PRIVATE_ROOM, SHARED_ROOM } from "./roomTypes";
import { SORT_BY_DEFAULT } from "./sortOrders";
import { defaultFromDate, defaultToDate } from "../util/dateutil";

export const DEFAULT_ZOOM_LEVEL = 12;
export const defaultFilterValues = {
  dateRange: [defaultFromDate.toJSDate(), defaultToDate.toJSDate()],
  propertyType: {
    [ENTIRE_HOME]: false,
    [PRIVATE_ROOM]: false,
    [SHARED_ROOM]: false,
  },
  bedrooms: [1, 50],
  guests: [1, 16],
  annualRevenue: [0, 1000000],
  annualOccupancy: [0, 100],
  beds: [1, 50],
  baths: [1, 50],
  minNights: [1, 366],
  reviews: [0, 1000],
  rating: [0, 5],
  photos: [1, 100],
  superhostOnly: false,
};

export const defaultSortOrder = SORT_BY_DEFAULT;
