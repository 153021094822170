import {
  SIGN_UP,
  LOG_IN,
  LOG_OUT,
  GET_SESSION,
  REFRESH_SESSION,
  AUTH_ERROR,
  SHOW_MODAL,
  FORGOT_PASSWORD,
  CONFIRM_PASSWORD,
} from "../actions/airbnbListingsActions";
import { CONFIRM_PASSWORD_MODAL } from "../constants/auth";

/**
 * note: modal is somewhat a misnomer, it actually represents next modal to display once current action succeeds
 */
const sessionReducer = (state = {}, action) => {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        ...state,
        modal: action.modal,
        error: action.error,
      };
    case SIGN_UP:
      return {
        ...state,
        ...action.session,
        authenticated: action.session.getIdToken() != null,
        error: null,
        modal: null,
      };
    case LOG_IN:
      return {
        ...state,
        ...action.session,
        authenticated: action.session.getIdToken() != null,
        error: null,
        modal: null,
      };
    case LOG_OUT:
      return {
        authenticated: false,
        error: null,
        modal: null,
      };
    case FORGOT_PASSWORD:
      return {
        authenticated: false,
        error: null,
        modal: CONFIRM_PASSWORD_MODAL,
      };
    case CONFIRM_PASSWORD:
      return {
        ...state,
        ...action.session,
        authenticated: action.session.getIdToken() != null,
        error: null,
        modal: null,
      };
    case GET_SESSION:
      return {
        ...state,
        ...action.session,
        authenticated: action.session.getIdToken() != null,
      };
    case REFRESH_SESSION:
      return {
        ...state,
        ...action.session,
        authenticated: action.session.getIdToken() != null,
      };
    case AUTH_ERROR:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};

export default sessionReducer;
