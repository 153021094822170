import {
  confirmPassword,
  deleteIdToken,
  forgotPassword,
  getSession,
  logIn,
  logOut,
  refreshSession,
  dropIdTokenAsCookie,
  signUp,
} from "../components/auth/cognito";
import { API_HOST } from "../constants/bootstrap";
import { ERROR } from "../constants/toastMessages";
import { setToast } from "./toastActions";

export const SET_DASHBOARD_DATA_READINESS = "SET_DASHBOARD_DATA_READINESS";
export const SET_LISTING_DATA_READINESS = "SET_LISTING_DATA_READINESS";

export const GET_DASHBOARD_LISTINGS = "GET_DASHBOARD_LISTINGS";
export const GET_LISTING = "GET_LISTING";
export const GET_LISTING_RULES = "GET_LISTING_RULES";
export const GET_CHANNEL_ACCOUNTS = "GET_CHANNEL_ACCOUNTS";
export const GET_BASE_PRICE_RECOMMENDATIONS = "GET_BASE_PRICE_RECOMMENDATIONS";
export const SYNC_LISTING_NOW = "SYNC_LISTING_NOW";
export const UPDATE_LISTING = "UPDATE_LISTING";
export const UPDATE_LISTING_RULES = "UPDATE_LISTING_RULES";
export const IMPORT_NEW_LISTINGS = "IMPORT_NEW_LISTINGS";

export const UPDATE_DASHBOARD_QUERY = "UPDATE_DASHBOARD_QUERY";
export const SET_SELECTABLE_MONTHS = "SET_SELECTABLE_MONTHS";
export const SELECT_LISTING_MONTH = "SELECT_LISTING_MONTH";

export const DELETE_LISTING = "DELETE_LISTING";
export const DELETE_CHANNEL_ACCOUNT = "DELETE_CHANNEL_ACCOUNT";

export const SET_LISTING_SETTINGS_DRAWER_VISIBILITY =
  "SET_LISTING_SETTINGS_DRAWER_VISIBILITY";

export const importNewListings = (result) => ({
  type: IMPORT_NEW_LISTINGS,
  ...result,
});

export const getListing = (result) => ({
  type: GET_LISTING,
  ...result,
});

export const updateListing = (result) => ({
  type: UPDATE_LISTING,
  ...result,
});

export const updateListingRules = (result) => ({
  type: UPDATE_LISTING_RULES,
  ...result,
});

export const getDashboardListings = (result) => ({
  type: GET_DASHBOARD_LISTINGS,
  ...result,
});

export const updateDashboardQuery = (result) => ({
  type: UPDATE_DASHBOARD_QUERY,
  ...result,
});

export const selectListingMonth = (result) => ({
  type: SELECT_LISTING_MONTH,
  ...result,
});

export const setDashboardDataReadiness = (result) => ({
  type: SET_DASHBOARD_DATA_READINESS,
  ...result,
});

export const setListingDataReadiness = (result) => ({
  type: SET_LISTING_DATA_READINESS,
  ...result,
});

export const getChannelAccounts = (result) => ({
  type: GET_CHANNEL_ACCOUNTS,
  ...result,
});

export const getBasePriceRecommendations = (result) => ({
  type: GET_BASE_PRICE_RECOMMENDATIONS,
  ...result,
});

export const setListingSettingsDrawerVisibility = (result) => ({
  type: SET_LISTING_SETTINGS_DRAWER_VISIBILITY,
  ...result,
});

export const fetchListing = (listingId, demo) => {
  return async (dispatch) => {
    let token = null;
    try {
      const session = await getSession();
      token = session.getIdToken().getJwtToken();
    } catch (err) {
      // if no session (no logged-in user is found), an error would show up here
      // console.warn(err);
    }
    const url = `${API_HOST}/dynamic_pricing/get_listing?channel=0&listing_id=${listingId}&demo=${demo}`;

    let requestInfo = {
      method: "GET",
      mode: "cors",
    };

    if (token != null) {
      requestInfo = {
        ...requestInfo,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    }

    try {
      const response = await fetch(url, requestInfo);
      if (response.ok) {
        const result = await response.json();
        dispatch(getListing(result));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchChannelAccounts = () => {
  return async (dispatch) => {
    let token = null;
    try {
      const session = await getSession();
      token = session.getIdToken().getJwtToken();
    } catch (err) {
      // if no session (no logged-in user is found), an error would show up here
      // console.warn(err);
    }
    const url = `${API_HOST}/dynamic_pricing/get_channel_accounts`;

    let requestInfo = {
      method: "GET",
      mode: "cors",
    };

    if (token != null) {
      requestInfo = {
        ...requestInfo,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    }

    try {
      const response = await fetch(url, requestInfo);
      if (response.ok) {
        const result = await response.json();
        dispatch(getChannelAccounts(result));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchDashboardListings = () => {
  return async (dispatch) => {
    let token = null;
    try {
      const session = await getSession();
      token = session.getIdToken().getJwtToken();
    } catch (err) {
      // if no session (no logged-in user is found), an error would show up here
      // console.warn(err);
    }
    const url = `${API_HOST}/dynamic_pricing/get_dashboard_listings`;

    let requestInfo = {
      method: "GET",
      mode: "cors",
    };

    if (token != null) {
      requestInfo = {
        ...requestInfo,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    }

    try {
      const response = await fetch(url, requestInfo);
      if (response.ok) {
        const result = await response.json();
        dispatch(getDashboardListings(result));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const modifyListing = (
  listingId,
  autoSync,
  basePrice,
  minPrice,
  maxPrice,
  overrides
) => {
  return async (dispatch) => {
    let token = null;
    try {
      const session = await getSession();
      token = session.getIdToken().getJwtToken();
    } catch (err) {
      // if no session (no logged-in user is found), an error would show up here
      // console.warn(err);
    }
    const url = `${API_HOST}/dynamic_pricing/update_listing`;

    let requestInfo = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        channel: 0,
        listingId,
        autoSync,
        ratesConfig: { basePrice, minPrice, maxPrice, overrides },
        groupId: null,
      }),
    };

    if (token != null) {
      requestInfo = {
        ...requestInfo,
        headers: {
          ...requestInfo.headers,
          Authorization: `Bearer ${token}`,
        },
      };
    }

    try {
      const response = await fetch(url, requestInfo);
      dispatch(setListingDataReadiness({ ready: false }));
      if (response.ok) {
        const result = await response.json();
        // ignored for now, and no dispatch action for updateListing because we do another fetchListing request
        dispatch(fetchListing(listingId, false));
        dispatch(fetchDashboardListings());
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const modifyListingRules = (listingId, referenceId, rules) => {
  return async (dispatch) => {
    let token = null;
    try {
      const session = await getSession();
      token = session.getIdToken().getJwtToken();
    } catch (err) {
      // if no session (no logged-in user is found), an error would show up here
      // console.warn(err);
    }
    const url = `${API_HOST}/dynamic_pricing/update_rules`;

    let requestInfo = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        tier: 0,
        referenceId,
        rules,
      }),
    };

    if (token != null) {
      requestInfo = {
        ...requestInfo,
        headers: {
          ...requestInfo.headers,
          Authorization: `Bearer ${token}`,
        },
      };
    }

    try {
      const response = await fetch(url, requestInfo);
      dispatch(setListingDataReadiness({ ready: false }));
      if (response.ok) {
        const result = await response.json();
        // ignored for now, and no dispatch action for updateListing because we do another fetchListing request
        dispatch(fetchListing(listingId, false));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchNewListings = (channelUserId) => {
  return async (dispatch) => {
    let token = null;
    try {
      const session = await getSession();
      token = session.getIdToken().getJwtToken();
    } catch (err) {
      // if no session (no logged-in user is found), an error would show up here
      // console.warn(err);
    }
    const url = `${API_HOST}/dynamic_pricing/import_new_listings`;

    let requestInfo = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        channel: 0,
        channelUserId,
      }),
    };

    if (token != null) {
      requestInfo = {
        ...requestInfo,
        headers: {
          ...requestInfo.headers,
          Authorization: `Bearer ${token}`,
        },
      };
    }

    try {
      const response = await fetch(url, requestInfo);
      if (response.ok) {
        const result = await response.json();
        const { successIds, failedIds, skippedIds } = result;
        // ignored for now, and no dispatch action

        dispatch(fetchDashboardListings());
        dispatch(
          setToast({
            level: "success",
            message: "Success! Any new listings have been imported.",
          })
        );
      }
    } catch (error) {
      console.log(error);
      dispatch(setToast({ level: "error", message: ERROR }));
    }
  };
};

export const deleteListing = (listingId) => {
  return async (dispatch) => {
    let token = null;
    try {
      const session = await getSession();
      token = session.getIdToken().getJwtToken();
    } catch (err) {
      // if no session (no logged-in user is found), an error would show up here
      // console.warn(err);
    }
    const url = `${API_HOST}/dynamic_pricing/delete_listing`;

    let requestInfo = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        channel: 0,
        listingId,
      }),
    };

    if (token != null) {
      requestInfo = {
        ...requestInfo,
        headers: {
          ...requestInfo.headers,
          Authorization: `Bearer ${token}`,
        },
      };
    }

    try {
      const response = await fetch(url, requestInfo);
      if (response.ok) {
        const result = await response.json();
        // ignored result for now once we get 200 OK

        dispatch(fetchDashboardListings());
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const deleteChannelAccount = (channelUserId) => {
  return async (dispatch) => {
    let token = null;
    try {
      const session = await getSession();
      token = session.getIdToken().getJwtToken();
    } catch (err) {
      // if no session (no logged-in user is found), an error would show up here
      // console.warn(err);
    }
    const url = `${API_HOST}/dynamic_pricing/delete_channel_account`;

    let requestInfo = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        channel: 0,
        channelUserId,
      }),
    };

    if (token != null) {
      requestInfo = {
        ...requestInfo,
        headers: {
          ...requestInfo.headers,
          Authorization: `Bearer ${token}`,
        },
      };
    }

    try {
      const response = await fetch(url, requestInfo);
      if (response.ok) {
        const result = await response.json();
        dispatch(fetchChannelAccounts());
        dispatch(
          setToast({
            level: "success",
            message: "Successfully removed the selected acccount.",
          })
        );
      }
    } catch (error) {
      console.log(error);
      dispatch(setToast({ level: "error", message: ERROR }));
    }
  };
};

export const fetchBasePriceRecommendations = () => {
  return async (dispatch, getState) => {
    const { latitude, longitude, entireHome, bedrooms, guests, baths } =
      getState().dynamicPricing.listing.listingInfo;

    let token = null;
    try {
      const session = await getSession();
      token = session.getIdToken().getJwtToken();
    } catch (err) {
      // if no session (no logged-in user is found), an error would show up here
      // console.warn(err);
    }
    let url = `${API_HOST}/dynamic_pricing/get_base_price_recommendations?latitude=${latitude}&longitude=${longitude}`;

    if (entireHome != null) {
      url += `&entire_home=${entireHome}`;
    }

    if (bedrooms != null) {
      url += `&bedrooms=${bedrooms}`;
    }

    if (guests != null) {
      url += `&guests=${guests}`;
    }

    if (baths != null) {
      url += `&baths=${baths}`;
    }

    let requestInfo = {
      method: "GET",
      mode: "cors",
    };

    if (token != null) {
      requestInfo = {
        ...requestInfo,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    }

    try {
      const response = await fetch(url, requestInfo);
      if (response.ok) {
        const result = await response.json();
        dispatch(getBasePriceRecommendations(result));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const syncListingNow = (listingId) => {
  return async (dispatch) => {
    let token = null;
    try {
      const session = await getSession();
      token = session.getIdToken().getJwtToken();
    } catch (err) {
      // if no session (no logged-in user is found), an error would show up here
      // console.warn(err);
    }
    const url = `${API_HOST}/dynamic_pricing/sync_listing_now`;

    let requestInfo = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        channel: 0,
        listingId,
      }),
    };

    if (token != null) {
      requestInfo = {
        ...requestInfo,
        headers: {
          ...requestInfo.headers,
          Authorization: `Bearer ${token}`,
        },
      };
    }

    try {
      const response = await fetch(url, requestInfo);
      if (response.ok) {
        const result = await response.json();
        dispatch(
          setToast({
            level: "success",
            message: "Listing synced successfully.",
          })
        );
      } else {
        dispatch(
          setToast({
            level: "error",
            message: "Error has occured during listing sync.",
          })
        );
      }
    } catch (error) {
      console.log(error);
      dispatch(setToast({ level: "error", message: ERROR }));
    }
  };
};
