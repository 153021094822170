/* eslint-disable no-param-reassign */
export const SET_TOAST = "SET_TOAST";
export const CLEAR_TOAST = "CLEAR_TOAST";

export const setToast = ({ level, message }) => ({
  type: SET_TOAST,
  level,
  message,
});

export const clearToast = () => ({
  type: CLEAR_TOAST,
  level: "",
  message: "",
});
