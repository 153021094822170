import React from "react";
import {
  Typography,
  Box,
  Container,
  useMediaQuery,
  useTheme,
  Grid,
} from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import withErrorBoundary from "./error/withErrorBoundary";

// Import company icons from react-icons
import {
  SiAirbnb,
  SiAmazon,
  SiMeta,
  SiFacebook,
  SiLinkedin,
  SiUber,
  SiPinterest,
} from "react-icons/si";

const styles = {
  container: {
    mx: { xs: 2, sm: 4, md: 10, lg: 20 },
    my: { xs: 8, md: 16 },
  },
  header: {
    textAlign: "center",
    mb: { xs: 4, md: 8 },
  },
  contentContainer: {
    maxWidth: "800px",
    mx: "auto",
    mt: { xs: 4, md: 8 },
  },
  section: {
    mb: 6,
  },
  sectionTitle: {
    color: "primary.main",
    mb: 2,
  },
  teamGrid: {
    mt: 3,
  },
  companyIcon: {
    fontSize: 36,
    transition: "transform 0.3s ease-in-out",
  },
};

const companiesData = [
  { name: "Airbnb", icon: SiAirbnb, color: "#FF5A5F" },
  { name: "LinkedIn", icon: SiLinkedin, color: "#0A66C2" },
  { name: "Amazon", icon: SiAmazon, color: "#FF9900" },
  { name: "Meta", icon: SiMeta, color: "#0668E1" },
  // { name: "Facebook", icon: SiFacebook, color: "#1877F2" },
  { name: "Uber", icon: SiUber, color: "#000000" },
  { name: "Pinterest", icon: SiPinterest, color: "#BD081C" },
];

const AboutUs = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box sx={styles.container}>
      <Container maxWidth="xl" disableGutters>
        <Box sx={styles.header}>
          <Typography variant={isMobile ? "h5" : "h4"} sx={{ mb: 2 }}>
            About AirROI
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: 16, md: 18 },
              maxWidth: "800px",
              mx: "auto",
              color: "text.secondary",
            }}
          >
            Empowering people with state-of-the-art short-term rental analytics
            and pricing tools
          </Typography>
        </Box>

        <Box sx={styles.contentContainer}>
          <Box sx={styles.section}>
            <Typography variant="h6" sx={styles.sectionTitle}>
              Our Story
            </Typography>
            <Typography paragraph>
              AirROI started as a passion project, born from the realization
              that high-quality short-term rental analytics tools were often
              prohibitively expensive for many hosts. We believed that every
              host should have access to powerful insights and tools to optimize
              their rentals, regardless of their budget.
            </Typography>
            <Typography paragraph>
              This conviction drove us to create a great product that helps
              hosts maximize their potential while keeping costs down—if not
              completely free. Our mission is to democratize access to
              sophisticated rental analytics and empower hosts of all sizes to
              make data-driven decisions.
            </Typography>
          </Box>

          <Box sx={styles.section}>
            <Typography variant="h6" sx={styles.sectionTitle}>
              Our Team
            </Typography>
            <Typography paragraph>
              At AirROI, we've assembled a team of exceptional talents from some
              of the world's leading tech companies. Our diverse group of
              professionals includes software engineers, machine learning
              experts, data scientists and hosts, all working together to bring
              you cutting-edge solutions.
            </Typography>
            <Typography paragraph>
              Our team come from companies such as:
            </Typography>
            <Grid container spacing={3} sx={styles.teamGrid}>
              {companiesData.map((company, index) => {
                const IconComponent = company.icon;
                return (
                  <Grid item key={index}>
                    <IconComponent
                      style={{
                        ...styles.companyIcon,
                        color: company.color,
                      }}
                    />
                  </Grid>
                );
              })}
            </Grid>
            <Typography paragraph sx={{ mt: 2 }}>
              This wealth of experience allows us to tackle complex challenges
              and deliver innovative solutions that make a real difference for
              our users.
            </Typography>
          </Box>

          <Box sx={styles.section}>
            <Typography variant="h6" sx={styles.sectionTitle}>
              Our Values
            </Typography>
            <Typography paragraph>
              At the core of AirROI, we believe in:
            </Typography>
            <ul>
              <li>
                <strong>Accessibility:</strong> Making powerful tools available
                to all
              </li>
              <li>
                <strong>Innovation:</strong> Continuously improving our
                offerings to stay ahead of market trends
              </li>
              <li>
                <strong>User-Centric Approach:</strong> Putting our users' needs
                at the forefront of everything we do
              </li>
              <li>
                <strong>Data-Driven Decision Making:</strong> Empowering people
                with accurate and actionable insights
              </li>
              <li>
                <strong>Transparency:</strong> Being open about our
                methodologies and communications
              </li>
            </ul>
          </Box>

          <Box sx={styles.section}>
            <Typography variant="h6" sx={styles.sectionTitle}>
              Our Commitment
            </Typography>
            <Typography paragraph>
              At AirROI, we're committed to providing you with the best possible
              tools and insights to succeed in the short-term rental market. We
              continuously strive to improve our services, adapt to market
              changes, and incorporate user feedback to ensure we're meeting
              your needs.
            </Typography>
            <Typography paragraph display="flex" alignItems="center">
              Most importantly, everything we do is made with love
              <FavoriteIcon sx={{ color: "red", ml: 1, fontSize: 20 }} />
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default withErrorBoundary(AboutUs);
