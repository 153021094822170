/* eslint-disable no-param-reassign */
import isEqual from "lodash.isequal";
import { DateTime } from "luxon";
import {
  confirmPassword,
  deleteIdToken,
  forgotPassword,
  getSession,
  logIn,
  logOut,
  refreshSession,
  dropIdTokenAsCookie,
  signUp,
} from "../components/auth/cognito";
import {
  CONFIRM_PASSWORD_MODAL,
  FORGOT_PASSWORD_MODAL,
  LOG_IN_MODAL,
  POST_SIGN_UP_LOG_IN_DELAY,
  SIGN_UP_MODAL,
} from "../constants/auth";
import {
  defaultFilterValues,
  defaultSortOrder,
} from "../constants/defaultValues";
import { toUTCKeepLocalTime } from "../util/dateutil";
import { delay } from "../util/jsutil";
import { setToast } from "./toastActions";
import { API_HOST } from "../constants/bootstrap";
import {
  fetchChannelAccounts,
  getChannelAccounts,
} from "./dynamicPricingActions";

export const SET_ATLAS_DEMO_FLAG = "SET_ATLAS_DEMO_FLAG";
export const SET_QUERY_DATA_READINESS = "SET_QUERY_DATA_READINESS";
export const RESET_AIRBNB_LISTINGS = "RESET_AIRBNB_LISTINGS";
export const RECEIVE_AIRBNB_LISTINGS_DETAILS =
  "RECEIVE_AIRBNB_LISTINGS_DETAILS";
export const RECEIVE_AIRBNB_LISTINGS = "RECEIVE_AIRBNB_LISTINGS";
export const FETCH_AIRBNB_LISTINGS = "FETCH_AIRBNB_LISTINGS";
export const RECEIVE_FOR_SALE_LISTINGS = "RECEIVE_FOR_SALE_LISTINGS";
export const RECEIVE_FOR_SALE_LISTING = "RECEIVE_FOR_SALE_LISTING";

export const UPDATE_LISTING_PRICE_TO_EVALUATE =
  "UPDATE_LISTING_PRICE_TO_EVALUATE";
export const UPDATE_DOWN_PAYMENT = "UPDATE_DOWN_PAYMENT";
export const UPDATE_DOWN_PAYMENT_PERCENTAGE = "UPDATE_DOWN_PAYMENT_PERCENTAGE";
export const UPDATE_INTEREST_RATE = "UPDATE_INTEREST_RATE";
export const UPDATE_PROPERTY_TAX_RATE = "UPDATE_PROPERTY_TAX_RATE";

export const UPDATE_LOCATION = "UPDATE_LOCATION";
export const UPDATE_FILTERS = "UPDATE_FILTERS";
export const UPDATE_SORT_ORDER = "UPDATE_SORT_ORDER";
export const UPDATE_CURRENCY = "UPDATE_CURRENCY";
export const UPDATE_GOOGLEMAP_VIEW = "UPDATE_GOOGLEMAP_VIEW";

export const SIGN_UP = "SIGN_UP";
export const LOG_IN = "LOG_IN";
export const LOG_OUT = "LOG_OUT";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const CONFIRM_PASSWORD = "CONFIRM_PASSWORD";
export const GET_SESSION = "GET_SESSION";
export const REFRESH_SESSION = "REFRESH_SESSION";
export const AUTH_ERROR = "AUTH_ERROR";
export const SHOW_MODAL = "SHOW_MODAL";
export const CLEAR_TOAST = "CLEAR_TOAST";

export const resetAirbnbListings = () => ({
  type: RESET_AIRBNB_LISTINGS,
});

export const setAtlasDemoFlag = (demo) => ({
  type: SET_ATLAS_DEMO_FLAG,
  demo,
});

export const setQueryDataReadiness = (ready) => ({
  type: SET_QUERY_DATA_READINESS,
  ready,
});

const receiveAirbnbListingsDetails = (result) => ({
  type: RECEIVE_AIRBNB_LISTINGS_DETAILS,
  ...result,
});

const receiveAirbnbListings = (result) => ({
  type: RECEIVE_AIRBNB_LISTINGS,
  ...result,
});

const receiveForSaleListings = (result) => ({
  type: RECEIVE_FOR_SALE_LISTINGS,
  ...result,
});

const receiveForSaleListing = (result) => ({
  type: RECEIVE_FOR_SALE_LISTING,
  ...result,
});

export const updateListingPriceToEvaluate = (price) => ({
  type: UPDATE_LISTING_PRICE_TO_EVALUATE,
  listingPriceToEvaluate: price,
});

export const updateDownPayment = (payment) => ({
  type: UPDATE_DOWN_PAYMENT,
  downPayment: payment,
});

export const updateDownPaymentPercentage = (percent) => ({
  type: UPDATE_DOWN_PAYMENT_PERCENTAGE,
  downPaymentPercentage: percent,
});

export const updateInterestRate = (rate) => ({
  type: UPDATE_INTEREST_RATE,
  interestRate: rate,
});

export const updatePropertyTaxRate = (rate) => ({
  type: UPDATE_PROPERTY_TAX_RATE,
  propertyTaxRate: rate,
});

export const updateLocation = (location) => ({
  type: UPDATE_LOCATION,
  location,
});

export const updateFilters = (filters) => ({
  type: UPDATE_FILTERS,
  filters,
});

export const updateSortOrder = (sortOrder) => ({
  type: UPDATE_SORT_ORDER,
  sortOrder,
});

export const updateCurrency = (currency) => ({
  type: UPDATE_CURRENCY,
  currency,
});

export const updateGoogleMapView = ({ lat, lng, zoom }) => ({
  type: UPDATE_GOOGLEMAP_VIEW,
  lat,
  lng,
  zoom,
});

// Query to generate data
/*
SELECT
    a.listing_id,
    b.latitude,
    b.longitude,
    a.state,
    a.zip_code,
    a.revenue,
    a.days_occupied
FROM
    ((SELECT
        *
    FROM
        airfoodwater.us_listings
    WHERE
        zip_code = 94010) a
    JOIN (SELECT
        *
    FROM
        airfoodwater.listings) b ON a.listing_id = b.listing_id)
*/

export const fetchAirbnbListings = (location, filters, sortOrder, currency) => {
  if (filters == null) {
    filters = {};
  }

  if (sortOrder == null) {
    sortOrder = defaultSortOrder;
  }

  return async (dispatch) => {
    let token = null;
    try {
      const session = await getSession();
      token = session.getIdToken().getJwtToken();
    } catch (err) {
      // if no session (no logged-in user is found), an error would show up here
      // console.warn(err);
    }

    const url = new URL(`${API_HOST}/bristleback/airbnb_listings`);

    // the following param could be null
    if (location.countryCode) {
      url.searchParams.append("country_code", location.countryCode);
    }
    if (location.state) {
      url.searchParams.append("state", location.state);
    }
    if (location.city) {
      url.searchParams.append("city", location.city);
    }
    if (location.neighborhood) {
      url.searchParams.append("neighborhood", location.neighborhood);
    }
    if (location.subdivision) {
      url.searchParams.append("subdivision", location.subdivision);
    }

    const exposedFilters = {
      ...filters,
    };

    Object.entries(exposedFilters).forEach((entry) => {
      const [key, value] = entry;

      if (
        !(key in defaultFilterValues) ||
        isEqual(value, defaultFilterValues[key])
      ) {
        delete exposedFilters[key];
      }
    });

    if ("dateRange" in exposedFilters) {
      const fromDate = DateTime.fromJSDate(exposedFilters.dateRange[0]);
      const toDate = DateTime.fromJSDate(exposedFilters.dateRange[1]);

      exposedFilters.dateRange = [
        toUTCKeepLocalTime(fromDate).toJSDate().getTime(),
        toUTCKeepLocalTime(toDate).toJSDate().getTime(),
      ];
    }

    url.searchParams.append("filters", JSON.stringify(exposedFilters));
    url.searchParams.append("sortOrder", sortOrder);

    if (currency != null) {
      url.searchParams.append("currency", currency);
    }

    let fetchRequestInfo = {
      method: "GET",
      mode: "cors",
    };

    if (token != null) {
      fetchRequestInfo = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        ...fetchRequestInfo,
      };
    }

    try {
      dispatch(setQueryDataReadiness(false));
      const response = await fetch(url.toString(), fetchRequestInfo);
      if (response.ok) {
        const result = await response.json();
        dispatch(receiveAirbnbListings(result));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchAirbnbListingsDetails = (ids, currency) => {
  return async (dispatch) => {
    let token = null;
    try {
      const session = await getSession();
      token = session.getIdToken().getJwtToken();
    } catch (err) {
      // if no session (no logged-in user is found), an error would show up here
      // console.warn(err);
    }

    const baseUrl = `${API_HOST}/bristleback/airbnb_listings_details`;
    const params = new URLSearchParams();

    if (currency != null) {
      params.append("currency", currency);
    }

    ids.forEach((id) => params.append("id", id));

    const url = `${baseUrl}?${params.toString()}`;

    let fetchRequestInfo = {
      method: "GET",
      mode: "cors",
    };

    if (token != null) {
      fetchRequestInfo = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        ...fetchRequestInfo,
      };
    }

    try {
      const response = await fetch(url, fetchRequestInfo);
      if (response.ok) {
        const result = await response.json();
        dispatch(receiveAirbnbListingsDetails(result));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchForSaleListings = (zipCode) => {
  return async (dispatch) => {
    const url = `${API_HOST}/bristleback/for_sale_listings?zip_code=${zipCode}`;

    try {
      const response = await fetch(url, {
        method: "GET",
        mode: "cors",
      });
      if (response.ok) {
        const result = await response.json();
        dispatch(receiveForSaleListings(result));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchForSaleListing = (streetAddress) => {
  return async (dispatch) => {
    const url = `${API_HOST}/bristleback/for_sale_listing?address=${streetAddress}`;

    try {
      const response = await fetch(url, {
        method: "GET",
        mode: "cors",
      });
      if (response.ok) {
        const result = await response.json();
        dispatch(receiveForSaleListing(result));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const signUpAction = (
  { firstName, lastName, email, password },
  successCallback
) => {
  return async (dispatch) => {
    try {
      await signUp(firstName, lastName, email, password);
      await delay(POST_SIGN_UP_LOG_IN_DELAY);
      const session = await logIn(email, password);

      // intentionally using SIGN_UP reducer on logIn() response, so that we show the correct toast
      dispatch({
        type: SIGN_UP,
        session,
      });

      dispatch(
        setToast({
          level: "success",
          message: "You have signed up and logged in.",
        })
      );

      dropIdTokenAsCookie(session.getIdToken().getJwtToken());

      setTimeout(() => {
        window.location.reload();
      }, 1000);
      successCallback();
    } catch (err) {
      dispatch({
        type: AUTH_ERROR,
        error: err.message,
      });
    }
  };
};

export const logInAction = ({ email, password }, successCallback) => {
  return async (dispatch) => {
    logIn(email, password)
      .then((session) => {
        dispatch({
          type: LOG_IN,
          session,
        });

        dropIdTokenAsCookie(session.getIdToken().getJwtToken());

        dispatch(setToast({ level: "success", message: "You are logged in." }));
        dispatch(fetchChannelAccounts());

        successCallback();

        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        dispatch({
          type: AUTH_ERROR,
          error: err.message,
        });
      });
  };
};

export const logOutAction = () => {
  return async (dispatch) => {
    logOut();
    dispatch({
      type: LOG_OUT,
    });
    dispatch(setToast({ level: "success", message: "You are logged out." }));
    dispatch(getChannelAccounts({ accounts: [] }));
    deleteIdToken();
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };
};

export const getSessionAction = () => {
  return async (dispatch) => {
    getSession().then((session) => {
      dispatch({
        type: GET_SESSION,
        session,
      });
    });
  };
};

export const forgotPasswordAction = (email) => {
  return async (dispatch) => {
    // intentionally not having error case, so that it's more secure as we do not tell end user whether the account exist or not
    forgotPassword(email).then(() => {
      dispatch({
        type: FORGOT_PASSWORD,
      });
      dispatch(
        setToast({
          level: "success",
          message: "An email was sent to the email you specified.",
        })
      );
    });
  };
};

export const confirmPasswordAction = (
  { email, verificationCode, newPassword },
  successCallback
) => {
  return async (dispatch) => {
    try {
      await confirmPassword(email, verificationCode, newPassword);
      const session = await logIn(email, newPassword);

      dispatch({
        type: CONFIRM_PASSWORD,
        session,
      });

      dispatch(
        setToast({
          level: "success",
          message: "Password was changed successuflly.",
        })
      );

      successCallback();
    } catch (err) {
      dispatch({
        type: AUTH_ERROR,
        error: err.message,
      });
    }
  };
};

export const refreshSessionAction = (forceRefresh) => {
  return async (dispatch) => {
    refreshSession(forceRefresh)
      .then((session) => {
        dispatch({
          type: GET_SESSION,
          session,
        });
        dropIdTokenAsCookie(session.getIdToken().getJwtToken());
      })
      .catch((err) => {
        // ignore
      });
  };
};

export const showSignUpModalAction = () => {
  return {
    type: SHOW_MODAL,
    modal: SIGN_UP_MODAL,
  };
};

export const showLogInModalAction = () => {
  return {
    type: SHOW_MODAL,
    modal: LOG_IN_MODAL,
  };
};

export const showForgotPasswordModalAction = () => {
  return {
    type: SHOW_MODAL,
    modal: FORGOT_PASSWORD_MODAL,
  };
};

export const showConfirmPasswordModalAction = () => {
  return {
    type: SHOW_MODAL,
    modal: CONFIRM_PASSWORD_MODAL,
  };
};

export const hideAuthModalAction = () => {
  return {
    type: SHOW_MODAL,
    modal: null,
    error: null,
  };
};

export const showAuthErrorAction = (message) => {
  return {
    type: AUTH_ERROR,
    error: message,
  };
};

export const clearToastAction = () => {
  return {
    type: CLEAR_TOAST,
  };
};
